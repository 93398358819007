.q-icon-picker {
  flex: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.q-icon-picker__body {
  overflow: hidden;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
}
.q-icon-picker__scroll-area {
  overflow: hidden;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}
.q-icon-picker__container {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.q-icon-picker__header {
  position: relative;
  width: 100%;
}
.q-icon-picker__footer {
  position: relative;
  width: 100%;
}
.q-icon-picker__pagination {
  position: relative;
}