@media (min-width: 0) {
  .flex-block,
  .flex-xs-block {
    display: block;
  }
  .flex-inline,
  .flex-xs-inline {
    display: inline-block;
  }
  .q-pa-xs-none {
    padding: 0 0;
  }
  .q-pl-xs-none,
  .q-px-xs-none {
    padding-left: 0;
  }
  .q-pr-xs-none,
  .q-px-xs-none {
    padding-right: 0;
  }
  .q-pt-xs-none,
  .q-py-xs-none {
    padding-top: 0;
  }
  .q-pb-xs-none,
  .q-py-xs-none {
    padding-bottom: 0;
  }
  .q-ma-xs-none {
    margin: 0 0;
  }
  .q-ml-xs-none,
  .q-mx-xs-none {
    margin-left: 0;
  }
  .q-mr-xs-none,
  .q-mx-xs-none {
    margin-right: 0;
  }
  .q-mt-xs-none,
  .q-my-xs-none {
    margin-top: 0;
  }
  .q-mb-xs-none,
  .q-my-xs-none {
    margin-bottom: 0;
  }
  .q-pa-xs-xs {
    padding: 4px 4px;
  }
  .q-pl-xs-xs,
  .q-px-xs-xs {
    padding-left: 4px;
  }
  .q-pr-xs-xs,
  .q-px-xs-xs {
    padding-right: 4px;
  }
  .q-pt-xs-xs,
  .q-py-xs-xs {
    padding-top: 4px;
  }
  .q-pb-xs-xs,
  .q-py-xs-xs {
    padding-bottom: 4px;
  }
  .q-ma-xs-xs {
    margin: 4px 4px;
  }
  .q-ml-xs-xs,
  .q-mx-xs-xs {
    margin-left: 4px;
  }
  .q-mr-xs-xs,
  .q-mx-xs-xs {
    margin-right: 4px;
  }
  .q-mt-xs-xs,
  .q-my-xs-xs {
    margin-top: 4px;
  }
  .q-mb-xs-xs,
  .q-my-xs-xs {
    margin-bottom: 4px;
  }
  .q-pa-xs-sm {
    padding: 8px 8px;
  }
  .q-pl-xs-sm,
  .q-px-xs-sm {
    padding-left: 8px;
  }
  .q-pr-xs-sm,
  .q-px-xs-sm {
    padding-right: 8px;
  }
  .q-pt-xs-sm,
  .q-py-xs-sm {
    padding-top: 8px;
  }
  .q-pb-xs-sm,
  .q-py-xs-sm {
    padding-bottom: 8px;
  }
  .q-ma-xs-sm {
    margin: 8px 8px;
  }
  .q-ml-xs-sm,
  .q-mx-xs-sm {
    margin-left: 8px;
  }
  .q-mr-xs-sm,
  .q-mx-xs-sm {
    margin-right: 8px;
  }
  .q-mt-xs-sm,
  .q-my-xs-sm {
    margin-top: 8px;
  }
  .q-mb-xs-sm,
  .q-my-xs-sm {
    margin-bottom: 8px;
  }
  .q-pa-xs-md {
    padding: 16px 16px;
  }
  .q-pl-xs-md,
  .q-px-xs-md {
    padding-left: 16px;
  }
  .q-pr-xs-md,
  .q-px-xs-md {
    padding-right: 16px;
  }
  .q-pt-xs-md,
  .q-py-xs-md {
    padding-top: 16px;
  }
  .q-pb-xs-md,
  .q-py-xs-md {
    padding-bottom: 16px;
  }
  .q-ma-xs-md {
    margin: 16px 16px;
  }
  .q-ml-xs-md,
  .q-mx-xs-md {
    margin-left: 16px;
  }
  .q-mr-xs-md,
  .q-mx-xs-md {
    margin-right: 16px;
  }
  .q-mt-xs-md,
  .q-my-xs-md {
    margin-top: 16px;
  }
  .q-mb-xs-md,
  .q-my-xs-md {
    margin-bottom: 16px;
  }
  .q-pa-xs-lg {
    padding: 24px 24px;
  }
  .q-pl-xs-lg,
  .q-px-xs-lg {
    padding-left: 24px;
  }
  .q-pr-xs-lg,
  .q-px-xs-lg {
    padding-right: 24px;
  }
  .q-pt-xs-lg,
  .q-py-xs-lg {
    padding-top: 24px;
  }
  .q-pb-xs-lg,
  .q-py-xs-lg {
    padding-bottom: 24px;
  }
  .q-ma-xs-lg {
    margin: 24px 24px;
  }
  .q-ml-xs-lg,
  .q-mx-xs-lg {
    margin-left: 24px;
  }
  .q-mr-xs-lg,
  .q-mx-xs-lg {
    margin-right: 24px;
  }
  .q-mt-xs-lg,
  .q-my-xs-lg {
    margin-top: 24px;
  }
  .q-mb-xs-lg,
  .q-my-xs-lg {
    margin-bottom: 24px;
  }
  .q-pa-xs-xl {
    padding: 48px 48px;
  }
  .q-pl-xs-xl,
  .q-px-xs-xl {
    padding-left: 48px;
  }
  .q-pr-xs-xl,
  .q-px-xs-xl {
    padding-right: 48px;
  }
  .q-pt-xs-xl,
  .q-py-xs-xl {
    padding-top: 48px;
  }
  .q-pb-xs-xl,
  .q-py-xs-xl {
    padding-bottom: 48px;
  }
  .q-ma-xs-xl {
    margin: 48px 48px;
  }
  .q-ml-xs-xl,
  .q-mx-xs-xl {
    margin-left: 48px;
  }
  .q-mr-xs-xl,
  .q-mx-xs-xl {
    margin-right: 48px;
  }
  .q-mt-xs-xl,
  .q-my-xs-xl {
    margin-top: 48px;
  }
  .q-mb-xs-xl,
  .q-my-xs-xl {
    margin-bottom: 48px;
  }
  .q-ml-xs-auto,
  .q-mx-xs-auto {
    margin-left: auto;
  }
  .q-mr-xs-auto,
  .q-mx-xs-auto {
    margin-right: auto;
  }
  .row-xs,
  .column-xs,
  .flex-xs {
    display: flex;
    flex-wrap: wrap;
  }
  .row-xs.inline-xs,
  .column-xs.inline-xs,
  .flex-xs.inline-xs {
    display: inline-flex;
  }
  .row-xs {
    flex-direction: row;
  }
  .row-xs.reverse-xs {
    flex-direction: row-reverse;
  }
  .column-xs {
    flex-direction: column;
  }
  .column-xs.reverse-xs {
    flex-direction: column-reverse;
  }
  .wrap-xs {
    flex-wrap: wrap;
  }
  .no-wrap-xs {
    flex-wrap: nowrap;
  }
  .reverse-wrap-xs {
    flex-wrap: wrap-reverse;
  }
  .order-xs-first {
    order: -10000;
  }
  .order-xs-last {
    order: 10000;
  }
  .order-xs-none {
    order: 0;
  }
  .justify-xs-start {
    justify-content: flex-start;
  }
  .justify-xs-end {
    justify-content: flex-end;
  }
  .justify-xs-center,
  .flex-xs-center {
    justify-content: center;
  }
  .justify-xs-between {
    justify-content: space-between;
  }
  .justify-xs-around {
    justify-content: space-around;
  }
  .justify-xs-evenly {
    justify-content: space-evenly;
  }
  .items-xs-start {
    align-items: flex-start;
  }
  .items-xs-end {
    align-items: flex-end;
  }
  .items-xs-center,
  .flex-xs-center {
    align-items: center;
  }
  .items-xs-baseline {
    align-items: baseline;
  }
  .items-xs-stretch {
    align-items: stretch;
  }
  .content-xs-start {
    align-content: flex-start;
  }
  .content-xs-end {
    align-content: flex-end;
  }
  .content-xs-center {
    align-content: center;
  }
  .content-xs-between {
    align-content: space-between;
  }
  .content-xs-around {
    align-content: space-around;
  }
  .self-xs-start {
    align-self: flex-start;
  }
  .self-xs-end {
    align-self: flex-end;
  }
  .self-xs-center {
    align-self: center;
  }
  .self-xs-baseline {
    align-self: baseline;
  }
  .self-xs-stretch {
    align-self: stretch;
  }
  .q-gutter-xs-x-none,
  .q-gutter-xs-none {
    margin-left: 0;
  }
  .q-gutter-xs-x-none > *,
  .q-gutter-xs-none > * {
    margin-left: 0;
  }
  .q-gutter-xs-y-none,
  .q-gutter-xs-none {
    margin-top: 0;
  }
  .q-gutter-xs-y-none > *,
  .q-gutter-xs-none > * {
    margin-top: 0;
  }
  .q-col-gutter-xs-x-none,
  .q-col-gutter-xs-none {
    margin-left: 0;
  }
  .q-col-gutter-xs-x-none > *,
  .q-col-gutter-xs-none > * {
    padding-left: 0;
  }
  .q-col-gutter-xs-y-none,
  .q-col-gutter-xs-none {
    margin-top: 0;
  }
  .q-col-gutter-xs-y-none > *,
  .q-col-gutter-xs-none > * {
    padding-top: 0;
  }
  .q-gutter-xs-x-xs,
  .q-gutter-xs-xs {
    margin-left: -4px;
  }
  .q-gutter-xs-x-xs > *,
  .q-gutter-xs-xs > * {
    margin-left: 4px;
  }
  .q-gutter-xs-y-xs,
  .q-gutter-xs-xs {
    margin-top: -4px;
  }
  .q-gutter-xs-y-xs > *,
  .q-gutter-xs-xs > * {
    margin-top: 4px;
  }
  .q-col-gutter-xs-x-xs,
  .q-col-gutter-xs-xs {
    margin-left: -4px;
  }
  .q-col-gutter-xs-x-xs > *,
  .q-col-gutter-xs-xs > * {
    padding-left: 4px;
  }
  .q-col-gutter-xs-y-xs,
  .q-col-gutter-xs-xs {
    margin-top: -4px;
  }
  .q-col-gutter-xs-y-xs > *,
  .q-col-gutter-xs-xs > * {
    padding-top: 4px;
  }
  .q-gutter-xs-x-sm,
  .q-gutter-xs-sm {
    margin-left: -8px;
  }
  .q-gutter-xs-x-sm > *,
  .q-gutter-xs-sm > * {
    margin-left: 8px;
  }
  .q-gutter-xs-y-sm,
  .q-gutter-xs-sm {
    margin-top: -8px;
  }
  .q-gutter-xs-y-sm > *,
  .q-gutter-xs-sm > * {
    margin-top: 8px;
  }
  .q-col-gutter-xs-x-sm,
  .q-col-gutter-xs-sm {
    margin-left: -8px;
  }
  .q-col-gutter-xs-x-sm > *,
  .q-col-gutter-xs-sm > * {
    padding-left: 8px;
  }
  .q-col-gutter-xs-y-sm,
  .q-col-gutter-xs-sm {
    margin-top: -8px;
  }
  .q-col-gutter-xs-y-sm > *,
  .q-col-gutter-xs-sm > * {
    padding-top: 8px;
  }
  .q-gutter-xs-x-md,
  .q-gutter-xs-md {
    margin-left: -16px;
  }
  .q-gutter-xs-x-md > *,
  .q-gutter-xs-md > * {
    margin-left: 16px;
  }
  .q-gutter-xs-y-md,
  .q-gutter-xs-md {
    margin-top: -16px;
  }
  .q-gutter-xs-y-md > *,
  .q-gutter-xs-md > * {
    margin-top: 16px;
  }
  .q-col-gutter-xs-x-md,
  .q-col-gutter-xs-md {
    margin-left: -16px;
  }
  .q-col-gutter-xs-x-md > *,
  .q-col-gutter-xs-md > * {
    padding-left: 16px;
  }
  .q-col-gutter-xs-y-md,
  .q-col-gutter-xs-md {
    margin-top: -16px;
  }
  .q-col-gutter-xs-y-md > *,
  .q-col-gutter-xs-md > * {
    padding-top: 16px;
  }
  .q-gutter-xs-x-lg,
  .q-gutter-xs-lg {
    margin-left: -24px;
  }
  .q-gutter-xs-x-lg > *,
  .q-gutter-xs-lg > * {
    margin-left: 24px;
  }
  .q-gutter-xs-y-lg,
  .q-gutter-xs-lg {
    margin-top: -24px;
  }
  .q-gutter-xs-y-lg > *,
  .q-gutter-xs-lg > * {
    margin-top: 24px;
  }
  .q-col-gutter-xs-x-lg,
  .q-col-gutter-xs-lg {
    margin-left: -24px;
  }
  .q-col-gutter-xs-x-lg > *,
  .q-col-gutter-xs-lg > * {
    padding-left: 24px;
  }
  .q-col-gutter-xs-y-lg,
  .q-col-gutter-xs-lg {
    margin-top: -24px;
  }
  .q-col-gutter-xs-y-lg > *,
  .q-col-gutter-xs-lg > * {
    padding-top: 24px;
  }
  .q-gutter-xs-x-xl,
  .q-gutter-xs-xl {
    margin-left: -48px;
  }
  .q-gutter-xs-x-xl > *,
  .q-gutter-xs-xl > * {
    margin-left: 48px;
  }
  .q-gutter-xs-y-xl,
  .q-gutter-xs-xl {
    margin-top: -48px;
  }
  .q-gutter-xs-y-xl > *,
  .q-gutter-xs-xl > * {
    margin-top: 48px;
  }
  .q-col-gutter-xs-x-xl,
  .q-col-gutter-xs-xl {
    margin-left: -48px;
  }
  .q-col-gutter-xs-x-xl > *,
  .q-col-gutter-xs-xl > * {
    padding-left: 48px;
  }
  .q-col-gutter-xs-y-xl,
  .q-col-gutter-xs-xl {
    margin-top: -48px;
  }
  .q-col-gutter-xs-y-xl > *,
  .q-col-gutter-xs-xl > * {
    padding-top: 48px;
  }
  .row-xs > .col-0,
  .row-xs > .col-xs-0 {
    height: auto;
    width: 0%;
  }
  .row-xs > .offset-0,
  .row-xs > .offset-xs-0 {
    margin-left: 0%;
  }
  .column-xs > .col-0,
  .column-xs > .col-xs-0 {
    height: 0%;
    width: auto;
  }
  .row-xs > .col-1,
  .row-xs > .col-xs-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-xs > .offset-1,
  .row-xs > .offset-xs-1 {
    margin-left: 8.3333%;
  }
  .column-xs > .col-1,
  .column-xs > .col-xs-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-xs > .col-2,
  .row-xs > .col-xs-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-xs > .offset-2,
  .row-xs > .offset-xs-2 {
    margin-left: 16.6667%;
  }
  .column-xs > .col-2,
  .column-xs > .col-xs-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-xs > .col-3,
  .row-xs > .col-xs-3 {
    height: auto;
    width: 25%;
  }
  .row-xs > .offset-3,
  .row-xs > .offset-xs-3 {
    margin-left: 25%;
  }
  .column-xs > .col-3,
  .column-xs > .col-xs-3 {
    height: 25%;
    width: auto;
  }
  .row-xs > .col-4,
  .row-xs > .col-xs-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-xs > .offset-4,
  .row-xs > .offset-xs-4 {
    margin-left: 33.3333%;
  }
  .column-xs > .col-4,
  .column-xs > .col-xs-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-xs > .col-5,
  .row-xs > .col-xs-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-xs > .offset-5,
  .row-xs > .offset-xs-5 {
    margin-left: 41.6667%;
  }
  .column-xs > .col-5,
  .column-xs > .col-xs-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-xs > .col-6,
  .row-xs > .col-xs-6 {
    height: auto;
    width: 50%;
  }
  .row-xs > .offset-6,
  .row-xs > .offset-xs-6 {
    margin-left: 50%;
  }
  .column-xs > .col-6,
  .column-xs > .col-xs-6 {
    height: 50%;
    width: auto;
  }
  .row-xs > .col-7,
  .row-xs > .col-xs-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-xs > .offset-7,
  .row-xs > .offset-xs-7 {
    margin-left: 58.3333%;
  }
  .column-xs > .col-7,
  .column-xs > .col-xs-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-xs > .col-8,
  .row-xs > .col-xs-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-xs > .offset-8,
  .row-xs > .offset-xs-8 {
    margin-left: 66.6667%;
  }
  .column-xs > .col-8,
  .column-xs > .col-xs-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-xs > .col-9,
  .row-xs > .col-xs-9 {
    height: auto;
    width: 75%;
  }
  .row-xs > .offset-9,
  .row-xs > .offset-xs-9 {
    margin-left: 75%;
  }
  .column-xs > .col-9,
  .column-xs > .col-xs-9 {
    height: 75%;
    width: auto;
  }
  .row-xs > .col-10,
  .row-xs > .col-xs-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-xs > .offset-10,
  .row-xs > .offset-xs-10 {
    margin-left: 83.3333%;
  }
  .column-xs > .col-10,
  .column-xs > .col-xs-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-xs > .col-11,
  .row-xs > .col-xs-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-xs > .offset-11,
  .row-xs > .offset-xs-11 {
    margin-left: 91.6667%;
  }
  .column-xs > .col-11,
  .column-xs > .col-xs-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-xs > .col-12,
  .row-xs > .col-xs-12 {
    height: auto;
    width: 100%;
  }
  .row-xs > .offset-12,
  .row-xs > .offset-xs-12 {
    margin-left: 100%;
  }
  .column-xs > .col-12,
  .column-xs > .col-xs-12 {
    height: 100%;
    width: auto;
  }
}
@media (min-width: 600px) {
  .flex-sm-block {
    display: block;
  }
  .flex-sm-inline {
    display: inline-block;
  }
  .q-pa-sm-none {
    padding: 0 0;
  }
  .q-pl-sm-none,
  .q-px-sm-none {
    padding-left: 0;
  }
  .q-pr-sm-none,
  .q-px-sm-none {
    padding-right: 0;
  }
  .q-pt-sm-none,
  .q-py-sm-none {
    padding-top: 0;
  }
  .q-pb-sm-none,
  .q-py-sm-none {
    padding-bottom: 0;
  }
  .q-ma-sm-none {
    margin: 0 0;
  }
  .q-ml-sm-none,
  .q-mx-sm-none {
    margin-left: 0;
  }
  .q-mr-sm-none,
  .q-mx-sm-none {
    margin-right: 0;
  }
  .q-mt-sm-none,
  .q-my-sm-none {
    margin-top: 0;
  }
  .q-mb-sm-none,
  .q-my-sm-none {
    margin-bottom: 0;
  }
  .q-pa-sm-xs {
    padding: 4px 4px;
  }
  .q-pl-sm-xs,
  .q-px-sm-xs {
    padding-left: 4px;
  }
  .q-pr-sm-xs,
  .q-px-sm-xs {
    padding-right: 4px;
  }
  .q-pt-sm-xs,
  .q-py-sm-xs {
    padding-top: 4px;
  }
  .q-pb-sm-xs,
  .q-py-sm-xs {
    padding-bottom: 4px;
  }
  .q-ma-sm-xs {
    margin: 4px 4px;
  }
  .q-ml-sm-xs,
  .q-mx-sm-xs {
    margin-left: 4px;
  }
  .q-mr-sm-xs,
  .q-mx-sm-xs {
    margin-right: 4px;
  }
  .q-mt-sm-xs,
  .q-my-sm-xs {
    margin-top: 4px;
  }
  .q-mb-sm-xs,
  .q-my-sm-xs {
    margin-bottom: 4px;
  }
  .q-pa-sm-sm {
    padding: 8px 8px;
  }
  .q-pl-sm-sm,
  .q-px-sm-sm {
    padding-left: 8px;
  }
  .q-pr-sm-sm,
  .q-px-sm-sm {
    padding-right: 8px;
  }
  .q-pt-sm-sm,
  .q-py-sm-sm {
    padding-top: 8px;
  }
  .q-pb-sm-sm,
  .q-py-sm-sm {
    padding-bottom: 8px;
  }
  .q-ma-sm-sm {
    margin: 8px 8px;
  }
  .q-ml-sm-sm,
  .q-mx-sm-sm {
    margin-left: 8px;
  }
  .q-mr-sm-sm,
  .q-mx-sm-sm {
    margin-right: 8px;
  }
  .q-mt-sm-sm,
  .q-my-sm-sm {
    margin-top: 8px;
  }
  .q-mb-sm-sm,
  .q-my-sm-sm {
    margin-bottom: 8px;
  }
  .q-pa-sm-md {
    padding: 16px 16px;
  }
  .q-pl-sm-md,
  .q-px-sm-md {
    padding-left: 16px;
  }
  .q-pr-sm-md,
  .q-px-sm-md {
    padding-right: 16px;
  }
  .q-pt-sm-md,
  .q-py-sm-md {
    padding-top: 16px;
  }
  .q-pb-sm-md,
  .q-py-sm-md {
    padding-bottom: 16px;
  }
  .q-ma-sm-md {
    margin: 16px 16px;
  }
  .q-ml-sm-md,
  .q-mx-sm-md {
    margin-left: 16px;
  }
  .q-mr-sm-md,
  .q-mx-sm-md {
    margin-right: 16px;
  }
  .q-mt-sm-md,
  .q-my-sm-md {
    margin-top: 16px;
  }
  .q-mb-sm-md,
  .q-my-sm-md {
    margin-bottom: 16px;
  }
  .q-pa-sm-lg {
    padding: 24px 24px;
  }
  .q-pl-sm-lg,
  .q-px-sm-lg {
    padding-left: 24px;
  }
  .q-pr-sm-lg,
  .q-px-sm-lg {
    padding-right: 24px;
  }
  .q-pt-sm-lg,
  .q-py-sm-lg {
    padding-top: 24px;
  }
  .q-pb-sm-lg,
  .q-py-sm-lg {
    padding-bottom: 24px;
  }
  .q-ma-sm-lg {
    margin: 24px 24px;
  }
  .q-ml-sm-lg,
  .q-mx-sm-lg {
    margin-left: 24px;
  }
  .q-mr-sm-lg,
  .q-mx-sm-lg {
    margin-right: 24px;
  }
  .q-mt-sm-lg,
  .q-my-sm-lg {
    margin-top: 24px;
  }
  .q-mb-sm-lg,
  .q-my-sm-lg {
    margin-bottom: 24px;
  }
  .q-pa-sm-xl {
    padding: 48px 48px;
  }
  .q-pl-sm-xl,
  .q-px-sm-xl {
    padding-left: 48px;
  }
  .q-pr-sm-xl,
  .q-px-sm-xl {
    padding-right: 48px;
  }
  .q-pt-sm-xl,
  .q-py-sm-xl {
    padding-top: 48px;
  }
  .q-pb-sm-xl,
  .q-py-sm-xl {
    padding-bottom: 48px;
  }
  .q-ma-sm-xl {
    margin: 48px 48px;
  }
  .q-ml-sm-xl,
  .q-mx-sm-xl {
    margin-left: 48px;
  }
  .q-mr-sm-xl,
  .q-mx-sm-xl {
    margin-right: 48px;
  }
  .q-mt-sm-xl,
  .q-my-sm-xl {
    margin-top: 48px;
  }
  .q-mb-sm-xl,
  .q-my-sm-xl {
    margin-bottom: 48px;
  }
  .q-ml-sm-auto,
  .q-mx-sm-auto {
    margin-left: auto;
  }
  .q-mr-sm-auto,
  .q-mx-sm-auto {
    margin-right: auto;
  }
  .row.inline-sm,
  .column.inline-sm,
  .flex.inline-sm {
    display: inline-flex;
  }
  .row-sm,
  .column-sm,
  .flex-sm {
    display: flex;
    flex-wrap: wrap;
  }
  .row-sm.inline,
  .column-sm.inline,
  .flex-sm.inline,
  .row-sm.inline-sm,
  .column-sm.inline-sm,
  .flex-sm.inline-sm {
    display: inline-flex;
  }
  .row.reverse-sm {
    flex-direction: row-reverse;
  }
  .row-sm {
    flex-direction: row;
  }
  .row-sm.reverse,
  .row-sm.reverse-sm {
    flex-direction: row-reverse;
  }
  .column.reverse-sm {
    flex-direction: column-reverse;
  }
  .column-sm {
    flex-direction: column;
  }
  .column-sm.reverse,
  .column-sm.reverse-sm {
    flex-direction: column-reverse;
  }
  .wrap-sm {
    flex-wrap: wrap;
  }
  .no-wrap-sm {
    flex-wrap: nowrap;
  }
  .reverse-wrap-sm {
    flex-wrap: wrap-reverse;
  }
  .order-sm-first {
    order: -10000;
  }
  .order-sm-last {
    order: 10000;
  }
  .order-sm-none {
    order: 0;
  }
  .justify-sm-start {
    justify-content: flex-start;
  }
  .justify-sm-end {
    justify-content: flex-end;
  }
  .justify-sm-center,
  .flex-sm-center {
    justify-content: center;
  }
  .justify-sm-between {
    justify-content: space-between;
  }
  .justify-sm-around {
    justify-content: space-around;
  }
  .justify-sm-evenly {
    justify-content: space-evenly;
  }
  .items-sm-start {
    align-items: flex-start;
  }
  .items-sm-end {
    align-items: flex-end;
  }
  .items-sm-center,
  .flex-sm-center {
    align-items: center;
  }
  .items-sm-baseline {
    align-items: baseline;
  }
  .items-sm-stretch {
    align-items: stretch;
  }
  .content-sm-start {
    align-content: flex-start;
  }
  .content-sm-end {
    align-content: flex-end;
  }
  .content-sm-center {
    align-content: center;
  }
  .content-sm-between {
    align-content: space-between;
  }
  .content-sm-around {
    align-content: space-around;
  }
  .self-sm-start {
    align-self: flex-start;
  }
  .self-sm-end {
    align-self: flex-end;
  }
  .self-sm-center {
    align-self: center;
  }
  .self-sm-baseline {
    align-self: baseline;
  }
  .self-sm-stretch {
    align-self: stretch;
  }
  .q-gutter-sm-x-none,
  .q-gutter-sm-none {
    margin-left: 0;
  }
  .q-gutter-sm-x-none > *,
  .q-gutter-sm-none > * {
    margin-left: 0;
  }
  .q-gutter-sm-y-none,
  .q-gutter-sm-none {
    margin-top: 0;
  }
  .q-gutter-sm-y-none > *,
  .q-gutter-sm-none > * {
    margin-top: 0;
  }
  .q-col-gutter-sm-x-none,
  .q-col-gutter-sm-none {
    margin-left: 0;
  }
  .q-col-gutter-sm-x-none > *,
  .q-col-gutter-sm-none > * {
    padding-left: 0;
  }
  .q-col-gutter-sm-y-none,
  .q-col-gutter-sm-none {
    margin-top: 0;
  }
  .q-col-gutter-sm-y-none > *,
  .q-col-gutter-sm-none > * {
    padding-top: 0;
  }
  .q-gutter-sm-x-xs,
  .q-gutter-sm-xs {
    margin-left: -4px;
  }
  .q-gutter-sm-x-xs > *,
  .q-gutter-sm-xs > * {
    margin-left: 4px;
  }
  .q-gutter-sm-y-xs,
  .q-gutter-sm-xs {
    margin-top: -4px;
  }
  .q-gutter-sm-y-xs > *,
  .q-gutter-sm-xs > * {
    margin-top: 4px;
  }
  .q-col-gutter-sm-x-xs,
  .q-col-gutter-sm-xs {
    margin-left: -4px;
  }
  .q-col-gutter-sm-x-xs > *,
  .q-col-gutter-sm-xs > * {
    padding-left: 4px;
  }
  .q-col-gutter-sm-y-xs,
  .q-col-gutter-sm-xs {
    margin-top: -4px;
  }
  .q-col-gutter-sm-y-xs > *,
  .q-col-gutter-sm-xs > * {
    padding-top: 4px;
  }
  .q-gutter-sm-x-sm,
  .q-gutter-sm-sm {
    margin-left: -8px;
  }
  .q-gutter-sm-x-sm > *,
  .q-gutter-sm-sm > * {
    margin-left: 8px;
  }
  .q-gutter-sm-y-sm,
  .q-gutter-sm-sm {
    margin-top: -8px;
  }
  .q-gutter-sm-y-sm > *,
  .q-gutter-sm-sm > * {
    margin-top: 8px;
  }
  .q-col-gutter-sm-x-sm,
  .q-col-gutter-sm-sm {
    margin-left: -8px;
  }
  .q-col-gutter-sm-x-sm > *,
  .q-col-gutter-sm-sm > * {
    padding-left: 8px;
  }
  .q-col-gutter-sm-y-sm,
  .q-col-gutter-sm-sm {
    margin-top: -8px;
  }
  .q-col-gutter-sm-y-sm > *,
  .q-col-gutter-sm-sm > * {
    padding-top: 8px;
  }
  .q-gutter-sm-x-md,
  .q-gutter-sm-md {
    margin-left: -16px;
  }
  .q-gutter-sm-x-md > *,
  .q-gutter-sm-md > * {
    margin-left: 16px;
  }
  .q-gutter-sm-y-md,
  .q-gutter-sm-md {
    margin-top: -16px;
  }
  .q-gutter-sm-y-md > *,
  .q-gutter-sm-md > * {
    margin-top: 16px;
  }
  .q-col-gutter-sm-x-md,
  .q-col-gutter-sm-md {
    margin-left: -16px;
  }
  .q-col-gutter-sm-x-md > *,
  .q-col-gutter-sm-md > * {
    padding-left: 16px;
  }
  .q-col-gutter-sm-y-md,
  .q-col-gutter-sm-md {
    margin-top: -16px;
  }
  .q-col-gutter-sm-y-md > *,
  .q-col-gutter-sm-md > * {
    padding-top: 16px;
  }
  .q-gutter-sm-x-lg,
  .q-gutter-sm-lg {
    margin-left: -24px;
  }
  .q-gutter-sm-x-lg > *,
  .q-gutter-sm-lg > * {
    margin-left: 24px;
  }
  .q-gutter-sm-y-lg,
  .q-gutter-sm-lg {
    margin-top: -24px;
  }
  .q-gutter-sm-y-lg > *,
  .q-gutter-sm-lg > * {
    margin-top: 24px;
  }
  .q-col-gutter-sm-x-lg,
  .q-col-gutter-sm-lg {
    margin-left: -24px;
  }
  .q-col-gutter-sm-x-lg > *,
  .q-col-gutter-sm-lg > * {
    padding-left: 24px;
  }
  .q-col-gutter-sm-y-lg,
  .q-col-gutter-sm-lg {
    margin-top: -24px;
  }
  .q-col-gutter-sm-y-lg > *,
  .q-col-gutter-sm-lg > * {
    padding-top: 24px;
  }
  .q-gutter-sm-x-xl,
  .q-gutter-sm-xl {
    margin-left: -48px;
  }
  .q-gutter-sm-x-xl > *,
  .q-gutter-sm-xl > * {
    margin-left: 48px;
  }
  .q-gutter-sm-y-xl,
  .q-gutter-sm-xl {
    margin-top: -48px;
  }
  .q-gutter-sm-y-xl > *,
  .q-gutter-sm-xl > * {
    margin-top: 48px;
  }
  .q-col-gutter-sm-x-xl,
  .q-col-gutter-sm-xl {
    margin-left: -48px;
  }
  .q-col-gutter-sm-x-xl > *,
  .q-col-gutter-sm-xl > * {
    padding-left: 48px;
  }
  .q-col-gutter-sm-y-xl,
  .q-col-gutter-sm-xl {
    margin-top: -48px;
  }
  .q-col-gutter-sm-y-xl > *,
  .q-col-gutter-sm-xl > * {
    padding-top: 48px;
  }
  .row-sm > .col-0,
  .row-sm > .col-xs-0 {
    height: auto;
    width: 0%;
  }
  .row-sm > .offset-0,
  .row-sm > .offset-xs-0 {
    margin-left: 0%;
  }
  .column-sm > .col-0,
  .column-sm > .col-xs-0 {
    height: 0%;
    width: auto;
  }
  .row-sm > .col-1,
  .row-sm > .col-xs-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-sm > .offset-1,
  .row-sm > .offset-xs-1 {
    margin-left: 8.3333%;
  }
  .column-sm > .col-1,
  .column-sm > .col-xs-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-sm > .col-2,
  .row-sm > .col-xs-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-sm > .offset-2,
  .row-sm > .offset-xs-2 {
    margin-left: 16.6667%;
  }
  .column-sm > .col-2,
  .column-sm > .col-xs-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-sm > .col-3,
  .row-sm > .col-xs-3 {
    height: auto;
    width: 25%;
  }
  .row-sm > .offset-3,
  .row-sm > .offset-xs-3 {
    margin-left: 25%;
  }
  .column-sm > .col-3,
  .column-sm > .col-xs-3 {
    height: 25%;
    width: auto;
  }
  .row-sm > .col-4,
  .row-sm > .col-xs-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-sm > .offset-4,
  .row-sm > .offset-xs-4 {
    margin-left: 33.3333%;
  }
  .column-sm > .col-4,
  .column-sm > .col-xs-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-sm > .col-5,
  .row-sm > .col-xs-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-sm > .offset-5,
  .row-sm > .offset-xs-5 {
    margin-left: 41.6667%;
  }
  .column-sm > .col-5,
  .column-sm > .col-xs-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-sm > .col-6,
  .row-sm > .col-xs-6 {
    height: auto;
    width: 50%;
  }
  .row-sm > .offset-6,
  .row-sm > .offset-xs-6 {
    margin-left: 50%;
  }
  .column-sm > .col-6,
  .column-sm > .col-xs-6 {
    height: 50%;
    width: auto;
  }
  .row-sm > .col-7,
  .row-sm > .col-xs-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-sm > .offset-7,
  .row-sm > .offset-xs-7 {
    margin-left: 58.3333%;
  }
  .column-sm > .col-7,
  .column-sm > .col-xs-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-sm > .col-8,
  .row-sm > .col-xs-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-sm > .offset-8,
  .row-sm > .offset-xs-8 {
    margin-left: 66.6667%;
  }
  .column-sm > .col-8,
  .column-sm > .col-xs-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-sm > .col-9,
  .row-sm > .col-xs-9 {
    height: auto;
    width: 75%;
  }
  .row-sm > .offset-9,
  .row-sm > .offset-xs-9 {
    margin-left: 75%;
  }
  .column-sm > .col-9,
  .column-sm > .col-xs-9 {
    height: 75%;
    width: auto;
  }
  .row-sm > .col-10,
  .row-sm > .col-xs-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-sm > .offset-10,
  .row-sm > .offset-xs-10 {
    margin-left: 83.3333%;
  }
  .column-sm > .col-10,
  .column-sm > .col-xs-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-sm > .col-11,
  .row-sm > .col-xs-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-sm > .offset-11,
  .row-sm > .offset-xs-11 {
    margin-left: 91.6667%;
  }
  .column-sm > .col-11,
  .column-sm > .col-xs-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-sm > .col-12,
  .row-sm > .col-xs-12 {
    height: auto;
    width: 100%;
  }
  .row-sm > .offset-12,
  .row-sm > .offset-xs-12 {
    margin-left: 100%;
  }
  .column-sm > .col-12,
  .column-sm > .col-xs-12 {
    height: 100%;
    width: auto;
  }
  .row-sm > .col-sm-0 {
    height: auto;
    width: 0%;
  }
  .row-sm > .offset-sm-0 {
    margin-left: 0%;
  }
  .column-sm > .col-sm-0 {
    height: 0%;
    width: auto;
  }
  .row-sm > .col-sm-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-sm > .offset-sm-1 {
    margin-left: 8.3333%;
  }
  .column-sm > .col-sm-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-sm > .col-sm-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-sm > .offset-sm-2 {
    margin-left: 16.6667%;
  }
  .column-sm > .col-sm-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-sm > .col-sm-3 {
    height: auto;
    width: 25%;
  }
  .row-sm > .offset-sm-3 {
    margin-left: 25%;
  }
  .column-sm > .col-sm-3 {
    height: 25%;
    width: auto;
  }
  .row-sm > .col-sm-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-sm > .offset-sm-4 {
    margin-left: 33.3333%;
  }
  .column-sm > .col-sm-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-sm > .col-sm-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-sm > .offset-sm-5 {
    margin-left: 41.6667%;
  }
  .column-sm > .col-sm-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-sm > .col-sm-6 {
    height: auto;
    width: 50%;
  }
  .row-sm > .offset-sm-6 {
    margin-left: 50%;
  }
  .column-sm > .col-sm-6 {
    height: 50%;
    width: auto;
  }
  .row-sm > .col-sm-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-sm > .offset-sm-7 {
    margin-left: 58.3333%;
  }
  .column-sm > .col-sm-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-sm > .col-sm-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-sm > .offset-sm-8 {
    margin-left: 66.6667%;
  }
  .column-sm > .col-sm-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-sm > .col-sm-9 {
    height: auto;
    width: 75%;
  }
  .row-sm > .offset-sm-9 {
    margin-left: 75%;
  }
  .column-sm > .col-sm-9 {
    height: 75%;
    width: auto;
  }
  .row-sm > .col-sm-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-sm > .offset-sm-10 {
    margin-left: 83.3333%;
  }
  .column-sm > .col-sm-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-sm > .col-sm-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-sm > .offset-sm-11 {
    margin-left: 91.6667%;
  }
  .column-sm > .col-sm-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-sm > .col-sm-12 {
    height: auto;
    width: 100%;
  }
  .row-sm > .offset-sm-12 {
    margin-left: 100%;
  }
  .column-sm > .col-sm-12 {
    height: 100%;
    width: auto;
  }
}
@media (min-width: 1024px) {
  .flex-md-block {
    display: block;
  }
  .flex-md-inline {
    display: inline-block;
  }
  .q-pa-md-none {
    padding: 0 0;
  }
  .q-pl-md-none,
  .q-px-md-none {
    padding-left: 0;
  }
  .q-pr-md-none,
  .q-px-md-none {
    padding-right: 0;
  }
  .q-pt-md-none,
  .q-py-md-none {
    padding-top: 0;
  }
  .q-pb-md-none,
  .q-py-md-none {
    padding-bottom: 0;
  }
  .q-ma-md-none {
    margin: 0 0;
  }
  .q-ml-md-none,
  .q-mx-md-none {
    margin-left: 0;
  }
  .q-mr-md-none,
  .q-mx-md-none {
    margin-right: 0;
  }
  .q-mt-md-none,
  .q-my-md-none {
    margin-top: 0;
  }
  .q-mb-md-none,
  .q-my-md-none {
    margin-bottom: 0;
  }
  .q-pa-md-xs {
    padding: 4px 4px;
  }
  .q-pl-md-xs,
  .q-px-md-xs {
    padding-left: 4px;
  }
  .q-pr-md-xs,
  .q-px-md-xs {
    padding-right: 4px;
  }
  .q-pt-md-xs,
  .q-py-md-xs {
    padding-top: 4px;
  }
  .q-pb-md-xs,
  .q-py-md-xs {
    padding-bottom: 4px;
  }
  .q-ma-md-xs {
    margin: 4px 4px;
  }
  .q-ml-md-xs,
  .q-mx-md-xs {
    margin-left: 4px;
  }
  .q-mr-md-xs,
  .q-mx-md-xs {
    margin-right: 4px;
  }
  .q-mt-md-xs,
  .q-my-md-xs {
    margin-top: 4px;
  }
  .q-mb-md-xs,
  .q-my-md-xs {
    margin-bottom: 4px;
  }
  .q-pa-md-sm {
    padding: 8px 8px;
  }
  .q-pl-md-sm,
  .q-px-md-sm {
    padding-left: 8px;
  }
  .q-pr-md-sm,
  .q-px-md-sm {
    padding-right: 8px;
  }
  .q-pt-md-sm,
  .q-py-md-sm {
    padding-top: 8px;
  }
  .q-pb-md-sm,
  .q-py-md-sm {
    padding-bottom: 8px;
  }
  .q-ma-md-sm {
    margin: 8px 8px;
  }
  .q-ml-md-sm,
  .q-mx-md-sm {
    margin-left: 8px;
  }
  .q-mr-md-sm,
  .q-mx-md-sm {
    margin-right: 8px;
  }
  .q-mt-md-sm,
  .q-my-md-sm {
    margin-top: 8px;
  }
  .q-mb-md-sm,
  .q-my-md-sm {
    margin-bottom: 8px;
  }
  .q-pa-md-md {
    padding: 16px 16px;
  }
  .q-pl-md-md,
  .q-px-md-md {
    padding-left: 16px;
  }
  .q-pr-md-md,
  .q-px-md-md {
    padding-right: 16px;
  }
  .q-pt-md-md,
  .q-py-md-md {
    padding-top: 16px;
  }
  .q-pb-md-md,
  .q-py-md-md {
    padding-bottom: 16px;
  }
  .q-ma-md-md {
    margin: 16px 16px;
  }
  .q-ml-md-md,
  .q-mx-md-md {
    margin-left: 16px;
  }
  .q-mr-md-md,
  .q-mx-md-md {
    margin-right: 16px;
  }
  .q-mt-md-md,
  .q-my-md-md {
    margin-top: 16px;
  }
  .q-mb-md-md,
  .q-my-md-md {
    margin-bottom: 16px;
  }
  .q-pa-md-lg {
    padding: 24px 24px;
  }
  .q-pl-md-lg,
  .q-px-md-lg {
    padding-left: 24px;
  }
  .q-pr-md-lg,
  .q-px-md-lg {
    padding-right: 24px;
  }
  .q-pt-md-lg,
  .q-py-md-lg {
    padding-top: 24px;
  }
  .q-pb-md-lg,
  .q-py-md-lg {
    padding-bottom: 24px;
  }
  .q-ma-md-lg {
    margin: 24px 24px;
  }
  .q-ml-md-lg,
  .q-mx-md-lg {
    margin-left: 24px;
  }
  .q-mr-md-lg,
  .q-mx-md-lg {
    margin-right: 24px;
  }
  .q-mt-md-lg,
  .q-my-md-lg {
    margin-top: 24px;
  }
  .q-mb-md-lg,
  .q-my-md-lg {
    margin-bottom: 24px;
  }
  .q-pa-md-xl {
    padding: 48px 48px;
  }
  .q-pl-md-xl,
  .q-px-md-xl {
    padding-left: 48px;
  }
  .q-pr-md-xl,
  .q-px-md-xl {
    padding-right: 48px;
  }
  .q-pt-md-xl,
  .q-py-md-xl {
    padding-top: 48px;
  }
  .q-pb-md-xl,
  .q-py-md-xl {
    padding-bottom: 48px;
  }
  .q-ma-md-xl {
    margin: 48px 48px;
  }
  .q-ml-md-xl,
  .q-mx-md-xl {
    margin-left: 48px;
  }
  .q-mr-md-xl,
  .q-mx-md-xl {
    margin-right: 48px;
  }
  .q-mt-md-xl,
  .q-my-md-xl {
    margin-top: 48px;
  }
  .q-mb-md-xl,
  .q-my-md-xl {
    margin-bottom: 48px;
  }
  .q-ml-md-auto,
  .q-mx-md-auto {
    margin-left: auto;
  }
  .q-mr-md-auto,
  .q-mx-md-auto {
    margin-right: auto;
  }
  .row.inline-md,
  .column.inline-md,
  .flex.inline-md {
    display: inline-flex;
  }
  .row-md,
  .column-md,
  .flex-md {
    display: flex;
    flex-wrap: wrap;
  }
  .row-md.inline,
  .column-md.inline,
  .flex-md.inline,
  .row-md.inline-md,
  .column-md.inline-md,
  .flex-md.inline-md {
    display: inline-flex;
  }
  .row.reverse-md {
    flex-direction: row-reverse;
  }
  .row-md {
    flex-direction: row;
  }
  .row-md.reverse,
  .row-md.reverse-md {
    flex-direction: row-reverse;
  }
  .column.reverse-md {
    flex-direction: column-reverse;
  }
  .column-md {
    flex-direction: column;
  }
  .column-md.reverse,
  .column-md.reverse-md {
    flex-direction: column-reverse;
  }
  .wrap-md {
    flex-wrap: wrap;
  }
  .no-wrap-md {
    flex-wrap: nowrap;
  }
  .reverse-wrap-md {
    flex-wrap: wrap-reverse;
  }
  .order-md-first {
    order: -10000;
  }
  .order-md-last {
    order: 10000;
  }
  .order-md-none {
    order: 0;
  }
  .justify-md-start {
    justify-content: flex-start;
  }
  .justify-md-end {
    justify-content: flex-end;
  }
  .justify-md-center,
  .flex-md-center {
    justify-content: center;
  }
  .justify-md-between {
    justify-content: space-between;
  }
  .justify-md-around {
    justify-content: space-around;
  }
  .justify-md-evenly {
    justify-content: space-evenly;
  }
  .items-md-start {
    align-items: flex-start;
  }
  .items-md-end {
    align-items: flex-end;
  }
  .items-md-center,
  .flex-md-center {
    align-items: center;
  }
  .items-md-baseline {
    align-items: baseline;
  }
  .items-md-stretch {
    align-items: stretch;
  }
  .content-md-start {
    align-content: flex-start;
  }
  .content-md-end {
    align-content: flex-end;
  }
  .content-md-center {
    align-content: center;
  }
  .content-md-between {
    align-content: space-between;
  }
  .content-md-around {
    align-content: space-around;
  }
  .self-md-start {
    align-self: flex-start;
  }
  .self-md-end {
    align-self: flex-end;
  }
  .self-md-center {
    align-self: center;
  }
  .self-md-baseline {
    align-self: baseline;
  }
  .self-md-stretch {
    align-self: stretch;
  }
  .q-gutter-md-x-none,
  .q-gutter-md-none {
    margin-left: 0;
  }
  .q-gutter-md-x-none > *,
  .q-gutter-md-none > * {
    margin-left: 0;
  }
  .q-gutter-md-y-none,
  .q-gutter-md-none {
    margin-top: 0;
  }
  .q-gutter-md-y-none > *,
  .q-gutter-md-none > * {
    margin-top: 0;
  }
  .q-col-gutter-md-x-none,
  .q-col-gutter-md-none {
    margin-left: 0;
  }
  .q-col-gutter-md-x-none > *,
  .q-col-gutter-md-none > * {
    padding-left: 0;
  }
  .q-col-gutter-md-y-none,
  .q-col-gutter-md-none {
    margin-top: 0;
  }
  .q-col-gutter-md-y-none > *,
  .q-col-gutter-md-none > * {
    padding-top: 0;
  }
  .q-gutter-md-x-xs,
  .q-gutter-md-xs {
    margin-left: -4px;
  }
  .q-gutter-md-x-xs > *,
  .q-gutter-md-xs > * {
    margin-left: 4px;
  }
  .q-gutter-md-y-xs,
  .q-gutter-md-xs {
    margin-top: -4px;
  }
  .q-gutter-md-y-xs > *,
  .q-gutter-md-xs > * {
    margin-top: 4px;
  }
  .q-col-gutter-md-x-xs,
  .q-col-gutter-md-xs {
    margin-left: -4px;
  }
  .q-col-gutter-md-x-xs > *,
  .q-col-gutter-md-xs > * {
    padding-left: 4px;
  }
  .q-col-gutter-md-y-xs,
  .q-col-gutter-md-xs {
    margin-top: -4px;
  }
  .q-col-gutter-md-y-xs > *,
  .q-col-gutter-md-xs > * {
    padding-top: 4px;
  }
  .q-gutter-md-x-sm,
  .q-gutter-md-sm {
    margin-left: -8px;
  }
  .q-gutter-md-x-sm > *,
  .q-gutter-md-sm > * {
    margin-left: 8px;
  }
  .q-gutter-md-y-sm,
  .q-gutter-md-sm {
    margin-top: -8px;
  }
  .q-gutter-md-y-sm > *,
  .q-gutter-md-sm > * {
    margin-top: 8px;
  }
  .q-col-gutter-md-x-sm,
  .q-col-gutter-md-sm {
    margin-left: -8px;
  }
  .q-col-gutter-md-x-sm > *,
  .q-col-gutter-md-sm > * {
    padding-left: 8px;
  }
  .q-col-gutter-md-y-sm,
  .q-col-gutter-md-sm {
    margin-top: -8px;
  }
  .q-col-gutter-md-y-sm > *,
  .q-col-gutter-md-sm > * {
    padding-top: 8px;
  }
  .q-gutter-md-x-md,
  .q-gutter-md-md {
    margin-left: -16px;
  }
  .q-gutter-md-x-md > *,
  .q-gutter-md-md > * {
    margin-left: 16px;
  }
  .q-gutter-md-y-md,
  .q-gutter-md-md {
    margin-top: -16px;
  }
  .q-gutter-md-y-md > *,
  .q-gutter-md-md > * {
    margin-top: 16px;
  }
  .q-col-gutter-md-x-md,
  .q-col-gutter-md-md {
    margin-left: -16px;
  }
  .q-col-gutter-md-x-md > *,
  .q-col-gutter-md-md > * {
    padding-left: 16px;
  }
  .q-col-gutter-md-y-md,
  .q-col-gutter-md-md {
    margin-top: -16px;
  }
  .q-col-gutter-md-y-md > *,
  .q-col-gutter-md-md > * {
    padding-top: 16px;
  }
  .q-gutter-md-x-lg,
  .q-gutter-md-lg {
    margin-left: -24px;
  }
  .q-gutter-md-x-lg > *,
  .q-gutter-md-lg > * {
    margin-left: 24px;
  }
  .q-gutter-md-y-lg,
  .q-gutter-md-lg {
    margin-top: -24px;
  }
  .q-gutter-md-y-lg > *,
  .q-gutter-md-lg > * {
    margin-top: 24px;
  }
  .q-col-gutter-md-x-lg,
  .q-col-gutter-md-lg {
    margin-left: -24px;
  }
  .q-col-gutter-md-x-lg > *,
  .q-col-gutter-md-lg > * {
    padding-left: 24px;
  }
  .q-col-gutter-md-y-lg,
  .q-col-gutter-md-lg {
    margin-top: -24px;
  }
  .q-col-gutter-md-y-lg > *,
  .q-col-gutter-md-lg > * {
    padding-top: 24px;
  }
  .q-gutter-md-x-xl,
  .q-gutter-md-xl {
    margin-left: -48px;
  }
  .q-gutter-md-x-xl > *,
  .q-gutter-md-xl > * {
    margin-left: 48px;
  }
  .q-gutter-md-y-xl,
  .q-gutter-md-xl {
    margin-top: -48px;
  }
  .q-gutter-md-y-xl > *,
  .q-gutter-md-xl > * {
    margin-top: 48px;
  }
  .q-col-gutter-md-x-xl,
  .q-col-gutter-md-xl {
    margin-left: -48px;
  }
  .q-col-gutter-md-x-xl > *,
  .q-col-gutter-md-xl > * {
    padding-left: 48px;
  }
  .q-col-gutter-md-y-xl,
  .q-col-gutter-md-xl {
    margin-top: -48px;
  }
  .q-col-gutter-md-y-xl > *,
  .q-col-gutter-md-xl > * {
    padding-top: 48px;
  }
  .row-md > .col-0,
  .row-md > .col-xs-0 {
    height: auto;
    width: 0%;
  }
  .row-md > .offset-0,
  .row-md > .offset-xs-0 {
    margin-left: 0%;
  }
  .column-md > .col-0,
  .column-md > .col-xs-0 {
    height: 0%;
    width: auto;
  }
  .row-md > .col-1,
  .row-md > .col-xs-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-md > .offset-1,
  .row-md > .offset-xs-1 {
    margin-left: 8.3333%;
  }
  .column-md > .col-1,
  .column-md > .col-xs-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-md > .col-2,
  .row-md > .col-xs-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-md > .offset-2,
  .row-md > .offset-xs-2 {
    margin-left: 16.6667%;
  }
  .column-md > .col-2,
  .column-md > .col-xs-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-md > .col-3,
  .row-md > .col-xs-3 {
    height: auto;
    width: 25%;
  }
  .row-md > .offset-3,
  .row-md > .offset-xs-3 {
    margin-left: 25%;
  }
  .column-md > .col-3,
  .column-md > .col-xs-3 {
    height: 25%;
    width: auto;
  }
  .row-md > .col-4,
  .row-md > .col-xs-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-md > .offset-4,
  .row-md > .offset-xs-4 {
    margin-left: 33.3333%;
  }
  .column-md > .col-4,
  .column-md > .col-xs-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-md > .col-5,
  .row-md > .col-xs-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-md > .offset-5,
  .row-md > .offset-xs-5 {
    margin-left: 41.6667%;
  }
  .column-md > .col-5,
  .column-md > .col-xs-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-md > .col-6,
  .row-md > .col-xs-6 {
    height: auto;
    width: 50%;
  }
  .row-md > .offset-6,
  .row-md > .offset-xs-6 {
    margin-left: 50%;
  }
  .column-md > .col-6,
  .column-md > .col-xs-6 {
    height: 50%;
    width: auto;
  }
  .row-md > .col-7,
  .row-md > .col-xs-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-md > .offset-7,
  .row-md > .offset-xs-7 {
    margin-left: 58.3333%;
  }
  .column-md > .col-7,
  .column-md > .col-xs-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-md > .col-8,
  .row-md > .col-xs-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-md > .offset-8,
  .row-md > .offset-xs-8 {
    margin-left: 66.6667%;
  }
  .column-md > .col-8,
  .column-md > .col-xs-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-md > .col-9,
  .row-md > .col-xs-9 {
    height: auto;
    width: 75%;
  }
  .row-md > .offset-9,
  .row-md > .offset-xs-9 {
    margin-left: 75%;
  }
  .column-md > .col-9,
  .column-md > .col-xs-9 {
    height: 75%;
    width: auto;
  }
  .row-md > .col-10,
  .row-md > .col-xs-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-md > .offset-10,
  .row-md > .offset-xs-10 {
    margin-left: 83.3333%;
  }
  .column-md > .col-10,
  .column-md > .col-xs-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-md > .col-11,
  .row-md > .col-xs-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-md > .offset-11,
  .row-md > .offset-xs-11 {
    margin-left: 91.6667%;
  }
  .column-md > .col-11,
  .column-md > .col-xs-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-md > .col-12,
  .row-md > .col-xs-12 {
    height: auto;
    width: 100%;
  }
  .row-md > .offset-12,
  .row-md > .offset-xs-12 {
    margin-left: 100%;
  }
  .column-md > .col-12,
  .column-md > .col-xs-12 {
    height: 100%;
    width: auto;
  }
  .row-md > .col-sm-0 {
    height: auto;
    width: 0%;
  }
  .row-md > .offset-sm-0 {
    margin-left: 0%;
  }
  .column-md > .col-sm-0 {
    height: 0%;
    width: auto;
  }
  .row-md > .col-sm-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-md > .offset-sm-1 {
    margin-left: 8.3333%;
  }
  .column-md > .col-sm-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-md > .col-sm-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-md > .offset-sm-2 {
    margin-left: 16.6667%;
  }
  .column-md > .col-sm-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-md > .col-sm-3 {
    height: auto;
    width: 25%;
  }
  .row-md > .offset-sm-3 {
    margin-left: 25%;
  }
  .column-md > .col-sm-3 {
    height: 25%;
    width: auto;
  }
  .row-md > .col-sm-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-md > .offset-sm-4 {
    margin-left: 33.3333%;
  }
  .column-md > .col-sm-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-md > .col-sm-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-md > .offset-sm-5 {
    margin-left: 41.6667%;
  }
  .column-md > .col-sm-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-md > .col-sm-6 {
    height: auto;
    width: 50%;
  }
  .row-md > .offset-sm-6 {
    margin-left: 50%;
  }
  .column-md > .col-sm-6 {
    height: 50%;
    width: auto;
  }
  .row-md > .col-sm-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-md > .offset-sm-7 {
    margin-left: 58.3333%;
  }
  .column-md > .col-sm-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-md > .col-sm-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-md > .offset-sm-8 {
    margin-left: 66.6667%;
  }
  .column-md > .col-sm-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-md > .col-sm-9 {
    height: auto;
    width: 75%;
  }
  .row-md > .offset-sm-9 {
    margin-left: 75%;
  }
  .column-md > .col-sm-9 {
    height: 75%;
    width: auto;
  }
  .row-md > .col-sm-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-md > .offset-sm-10 {
    margin-left: 83.3333%;
  }
  .column-md > .col-sm-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-md > .col-sm-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-md > .offset-sm-11 {
    margin-left: 91.6667%;
  }
  .column-md > .col-sm-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-md > .col-sm-12 {
    height: auto;
    width: 100%;
  }
  .row-md > .offset-sm-12 {
    margin-left: 100%;
  }
  .column-md > .col-sm-12 {
    height: 100%;
    width: auto;
  }
  .row-md > .col-md-0 {
    height: auto;
    width: 0%;
  }
  .row-md > .offset-md-0 {
    margin-left: 0%;
  }
  .column-md > .col-md-0 {
    height: 0%;
    width: auto;
  }
  .row-md > .col-md-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-md > .offset-md-1 {
    margin-left: 8.3333%;
  }
  .column-md > .col-md-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-md > .col-md-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-md > .offset-md-2 {
    margin-left: 16.6667%;
  }
  .column-md > .col-md-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-md > .col-md-3 {
    height: auto;
    width: 25%;
  }
  .row-md > .offset-md-3 {
    margin-left: 25%;
  }
  .column-md > .col-md-3 {
    height: 25%;
    width: auto;
  }
  .row-md > .col-md-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-md > .offset-md-4 {
    margin-left: 33.3333%;
  }
  .column-md > .col-md-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-md > .col-md-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-md > .offset-md-5 {
    margin-left: 41.6667%;
  }
  .column-md > .col-md-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-md > .col-md-6 {
    height: auto;
    width: 50%;
  }
  .row-md > .offset-md-6 {
    margin-left: 50%;
  }
  .column-md > .col-md-6 {
    height: 50%;
    width: auto;
  }
  .row-md > .col-md-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-md > .offset-md-7 {
    margin-left: 58.3333%;
  }
  .column-md > .col-md-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-md > .col-md-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-md > .offset-md-8 {
    margin-left: 66.6667%;
  }
  .column-md > .col-md-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-md > .col-md-9 {
    height: auto;
    width: 75%;
  }
  .row-md > .offset-md-9 {
    margin-left: 75%;
  }
  .column-md > .col-md-9 {
    height: 75%;
    width: auto;
  }
  .row-md > .col-md-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-md > .offset-md-10 {
    margin-left: 83.3333%;
  }
  .column-md > .col-md-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-md > .col-md-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-md > .offset-md-11 {
    margin-left: 91.6667%;
  }
  .column-md > .col-md-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-md > .col-md-12 {
    height: auto;
    width: 100%;
  }
  .row-md > .offset-md-12 {
    margin-left: 100%;
  }
  .column-md > .col-md-12 {
    height: 100%;
    width: auto;
  }
}
@media (min-width: 1440px) {
  .flex-lg-block {
    display: block;
  }
  .flex-lg-inline {
    display: inline-block;
  }
  .q-pa-lg-none {
    padding: 0 0;
  }
  .q-pl-lg-none,
  .q-px-lg-none {
    padding-left: 0;
  }
  .q-pr-lg-none,
  .q-px-lg-none {
    padding-right: 0;
  }
  .q-pt-lg-none,
  .q-py-lg-none {
    padding-top: 0;
  }
  .q-pb-lg-none,
  .q-py-lg-none {
    padding-bottom: 0;
  }
  .q-ma-lg-none {
    margin: 0 0;
  }
  .q-ml-lg-none,
  .q-mx-lg-none {
    margin-left: 0;
  }
  .q-mr-lg-none,
  .q-mx-lg-none {
    margin-right: 0;
  }
  .q-mt-lg-none,
  .q-my-lg-none {
    margin-top: 0;
  }
  .q-mb-lg-none,
  .q-my-lg-none {
    margin-bottom: 0;
  }
  .q-pa-lg-xs {
    padding: 4px 4px;
  }
  .q-pl-lg-xs,
  .q-px-lg-xs {
    padding-left: 4px;
  }
  .q-pr-lg-xs,
  .q-px-lg-xs {
    padding-right: 4px;
  }
  .q-pt-lg-xs,
  .q-py-lg-xs {
    padding-top: 4px;
  }
  .q-pb-lg-xs,
  .q-py-lg-xs {
    padding-bottom: 4px;
  }
  .q-ma-lg-xs {
    margin: 4px 4px;
  }
  .q-ml-lg-xs,
  .q-mx-lg-xs {
    margin-left: 4px;
  }
  .q-mr-lg-xs,
  .q-mx-lg-xs {
    margin-right: 4px;
  }
  .q-mt-lg-xs,
  .q-my-lg-xs {
    margin-top: 4px;
  }
  .q-mb-lg-xs,
  .q-my-lg-xs {
    margin-bottom: 4px;
  }
  .q-pa-lg-sm {
    padding: 8px 8px;
  }
  .q-pl-lg-sm,
  .q-px-lg-sm {
    padding-left: 8px;
  }
  .q-pr-lg-sm,
  .q-px-lg-sm {
    padding-right: 8px;
  }
  .q-pt-lg-sm,
  .q-py-lg-sm {
    padding-top: 8px;
  }
  .q-pb-lg-sm,
  .q-py-lg-sm {
    padding-bottom: 8px;
  }
  .q-ma-lg-sm {
    margin: 8px 8px;
  }
  .q-ml-lg-sm,
  .q-mx-lg-sm {
    margin-left: 8px;
  }
  .q-mr-lg-sm,
  .q-mx-lg-sm {
    margin-right: 8px;
  }
  .q-mt-lg-sm,
  .q-my-lg-sm {
    margin-top: 8px;
  }
  .q-mb-lg-sm,
  .q-my-lg-sm {
    margin-bottom: 8px;
  }
  .q-pa-lg-md {
    padding: 16px 16px;
  }
  .q-pl-lg-md,
  .q-px-lg-md {
    padding-left: 16px;
  }
  .q-pr-lg-md,
  .q-px-lg-md {
    padding-right: 16px;
  }
  .q-pt-lg-md,
  .q-py-lg-md {
    padding-top: 16px;
  }
  .q-pb-lg-md,
  .q-py-lg-md {
    padding-bottom: 16px;
  }
  .q-ma-lg-md {
    margin: 16px 16px;
  }
  .q-ml-lg-md,
  .q-mx-lg-md {
    margin-left: 16px;
  }
  .q-mr-lg-md,
  .q-mx-lg-md {
    margin-right: 16px;
  }
  .q-mt-lg-md,
  .q-my-lg-md {
    margin-top: 16px;
  }
  .q-mb-lg-md,
  .q-my-lg-md {
    margin-bottom: 16px;
  }
  .q-pa-lg-lg {
    padding: 24px 24px;
  }
  .q-pl-lg-lg,
  .q-px-lg-lg {
    padding-left: 24px;
  }
  .q-pr-lg-lg,
  .q-px-lg-lg {
    padding-right: 24px;
  }
  .q-pt-lg-lg,
  .q-py-lg-lg {
    padding-top: 24px;
  }
  .q-pb-lg-lg,
  .q-py-lg-lg {
    padding-bottom: 24px;
  }
  .q-ma-lg-lg {
    margin: 24px 24px;
  }
  .q-ml-lg-lg,
  .q-mx-lg-lg {
    margin-left: 24px;
  }
  .q-mr-lg-lg,
  .q-mx-lg-lg {
    margin-right: 24px;
  }
  .q-mt-lg-lg,
  .q-my-lg-lg {
    margin-top: 24px;
  }
  .q-mb-lg-lg,
  .q-my-lg-lg {
    margin-bottom: 24px;
  }
  .q-pa-lg-xl {
    padding: 48px 48px;
  }
  .q-pl-lg-xl,
  .q-px-lg-xl {
    padding-left: 48px;
  }
  .q-pr-lg-xl,
  .q-px-lg-xl {
    padding-right: 48px;
  }
  .q-pt-lg-xl,
  .q-py-lg-xl {
    padding-top: 48px;
  }
  .q-pb-lg-xl,
  .q-py-lg-xl {
    padding-bottom: 48px;
  }
  .q-ma-lg-xl {
    margin: 48px 48px;
  }
  .q-ml-lg-xl,
  .q-mx-lg-xl {
    margin-left: 48px;
  }
  .q-mr-lg-xl,
  .q-mx-lg-xl {
    margin-right: 48px;
  }
  .q-mt-lg-xl,
  .q-my-lg-xl {
    margin-top: 48px;
  }
  .q-mb-lg-xl,
  .q-my-lg-xl {
    margin-bottom: 48px;
  }
  .q-ml-lg-auto,
  .q-mx-lg-auto {
    margin-left: auto;
  }
  .q-mr-lg-auto,
  .q-mx-lg-auto {
    margin-right: auto;
  }
  .row.inline-lg,
  .column.inline-lg,
  .flex.inline-lg {
    display: inline-flex;
  }
  .row-lg,
  .column-lg,
  .flex-lg {
    display: flex;
    flex-wrap: wrap;
  }
  .row-lg.inline,
  .column-lg.inline,
  .flex-lg.inline,
  .row-lg.inline-lg,
  .column-lg.inline-lg,
  .flex-lg.inline-lg {
    display: inline-flex;
  }
  .row.reverse-lg {
    flex-direction: row-reverse;
  }
  .row-lg {
    flex-direction: row;
  }
  .row-lg.reverse,
  .row-lg.reverse-lg {
    flex-direction: row-reverse;
  }
  .column.reverse-lg {
    flex-direction: column-reverse;
  }
  .column-lg {
    flex-direction: column;
  }
  .column-lg.reverse,
  .column-lg.reverse-lg {
    flex-direction: column-reverse;
  }
  .wrap-lg {
    flex-wrap: wrap;
  }
  .no-wrap-lg {
    flex-wrap: nowrap;
  }
  .reverse-wrap-lg {
    flex-wrap: wrap-reverse;
  }
  .order-lg-first {
    order: -10000;
  }
  .order-lg-last {
    order: 10000;
  }
  .order-lg-none {
    order: 0;
  }
  .justify-lg-start {
    justify-content: flex-start;
  }
  .justify-lg-end {
    justify-content: flex-end;
  }
  .justify-lg-center,
  .flex-lg-center {
    justify-content: center;
  }
  .justify-lg-between {
    justify-content: space-between;
  }
  .justify-lg-around {
    justify-content: space-around;
  }
  .justify-lg-evenly {
    justify-content: space-evenly;
  }
  .items-lg-start {
    align-items: flex-start;
  }
  .items-lg-end {
    align-items: flex-end;
  }
  .items-lg-center,
  .flex-lg-center {
    align-items: center;
  }
  .items-lg-baseline {
    align-items: baseline;
  }
  .items-lg-stretch {
    align-items: stretch;
  }
  .content-lg-start {
    align-content: flex-start;
  }
  .content-lg-end {
    align-content: flex-end;
  }
  .content-lg-center {
    align-content: center;
  }
  .content-lg-between {
    align-content: space-between;
  }
  .content-lg-around {
    align-content: space-around;
  }
  .self-lg-start {
    align-self: flex-start;
  }
  .self-lg-end {
    align-self: flex-end;
  }
  .self-lg-center {
    align-self: center;
  }
  .self-lg-baseline {
    align-self: baseline;
  }
  .self-lg-stretch {
    align-self: stretch;
  }
  .q-gutter-lg-x-none,
  .q-gutter-lg-none {
    margin-left: 0;
  }
  .q-gutter-lg-x-none > *,
  .q-gutter-lg-none > * {
    margin-left: 0;
  }
  .q-gutter-lg-y-none,
  .q-gutter-lg-none {
    margin-top: 0;
  }
  .q-gutter-lg-y-none > *,
  .q-gutter-lg-none > * {
    margin-top: 0;
  }
  .q-col-gutter-lg-x-none,
  .q-col-gutter-lg-none {
    margin-left: 0;
  }
  .q-col-gutter-lg-x-none > *,
  .q-col-gutter-lg-none > * {
    padding-left: 0;
  }
  .q-col-gutter-lg-y-none,
  .q-col-gutter-lg-none {
    margin-top: 0;
  }
  .q-col-gutter-lg-y-none > *,
  .q-col-gutter-lg-none > * {
    padding-top: 0;
  }
  .q-gutter-lg-x-xs,
  .q-gutter-lg-xs {
    margin-left: -4px;
  }
  .q-gutter-lg-x-xs > *,
  .q-gutter-lg-xs > * {
    margin-left: 4px;
  }
  .q-gutter-lg-y-xs,
  .q-gutter-lg-xs {
    margin-top: -4px;
  }
  .q-gutter-lg-y-xs > *,
  .q-gutter-lg-xs > * {
    margin-top: 4px;
  }
  .q-col-gutter-lg-x-xs,
  .q-col-gutter-lg-xs {
    margin-left: -4px;
  }
  .q-col-gutter-lg-x-xs > *,
  .q-col-gutter-lg-xs > * {
    padding-left: 4px;
  }
  .q-col-gutter-lg-y-xs,
  .q-col-gutter-lg-xs {
    margin-top: -4px;
  }
  .q-col-gutter-lg-y-xs > *,
  .q-col-gutter-lg-xs > * {
    padding-top: 4px;
  }
  .q-gutter-lg-x-sm,
  .q-gutter-lg-sm {
    margin-left: -8px;
  }
  .q-gutter-lg-x-sm > *,
  .q-gutter-lg-sm > * {
    margin-left: 8px;
  }
  .q-gutter-lg-y-sm,
  .q-gutter-lg-sm {
    margin-top: -8px;
  }
  .q-gutter-lg-y-sm > *,
  .q-gutter-lg-sm > * {
    margin-top: 8px;
  }
  .q-col-gutter-lg-x-sm,
  .q-col-gutter-lg-sm {
    margin-left: -8px;
  }
  .q-col-gutter-lg-x-sm > *,
  .q-col-gutter-lg-sm > * {
    padding-left: 8px;
  }
  .q-col-gutter-lg-y-sm,
  .q-col-gutter-lg-sm {
    margin-top: -8px;
  }
  .q-col-gutter-lg-y-sm > *,
  .q-col-gutter-lg-sm > * {
    padding-top: 8px;
  }
  .q-gutter-lg-x-md,
  .q-gutter-lg-md {
    margin-left: -16px;
  }
  .q-gutter-lg-x-md > *,
  .q-gutter-lg-md > * {
    margin-left: 16px;
  }
  .q-gutter-lg-y-md,
  .q-gutter-lg-md {
    margin-top: -16px;
  }
  .q-gutter-lg-y-md > *,
  .q-gutter-lg-md > * {
    margin-top: 16px;
  }
  .q-col-gutter-lg-x-md,
  .q-col-gutter-lg-md {
    margin-left: -16px;
  }
  .q-col-gutter-lg-x-md > *,
  .q-col-gutter-lg-md > * {
    padding-left: 16px;
  }
  .q-col-gutter-lg-y-md,
  .q-col-gutter-lg-md {
    margin-top: -16px;
  }
  .q-col-gutter-lg-y-md > *,
  .q-col-gutter-lg-md > * {
    padding-top: 16px;
  }
  .q-gutter-lg-x-lg,
  .q-gutter-lg-lg {
    margin-left: -24px;
  }
  .q-gutter-lg-x-lg > *,
  .q-gutter-lg-lg > * {
    margin-left: 24px;
  }
  .q-gutter-lg-y-lg,
  .q-gutter-lg-lg {
    margin-top: -24px;
  }
  .q-gutter-lg-y-lg > *,
  .q-gutter-lg-lg > * {
    margin-top: 24px;
  }
  .q-col-gutter-lg-x-lg,
  .q-col-gutter-lg-lg {
    margin-left: -24px;
  }
  .q-col-gutter-lg-x-lg > *,
  .q-col-gutter-lg-lg > * {
    padding-left: 24px;
  }
  .q-col-gutter-lg-y-lg,
  .q-col-gutter-lg-lg {
    margin-top: -24px;
  }
  .q-col-gutter-lg-y-lg > *,
  .q-col-gutter-lg-lg > * {
    padding-top: 24px;
  }
  .q-gutter-lg-x-xl,
  .q-gutter-lg-xl {
    margin-left: -48px;
  }
  .q-gutter-lg-x-xl > *,
  .q-gutter-lg-xl > * {
    margin-left: 48px;
  }
  .q-gutter-lg-y-xl,
  .q-gutter-lg-xl {
    margin-top: -48px;
  }
  .q-gutter-lg-y-xl > *,
  .q-gutter-lg-xl > * {
    margin-top: 48px;
  }
  .q-col-gutter-lg-x-xl,
  .q-col-gutter-lg-xl {
    margin-left: -48px;
  }
  .q-col-gutter-lg-x-xl > *,
  .q-col-gutter-lg-xl > * {
    padding-left: 48px;
  }
  .q-col-gutter-lg-y-xl,
  .q-col-gutter-lg-xl {
    margin-top: -48px;
  }
  .q-col-gutter-lg-y-xl > *,
  .q-col-gutter-lg-xl > * {
    padding-top: 48px;
  }
  .row-lg > .col-0,
  .row-lg > .col-xs-0 {
    height: auto;
    width: 0%;
  }
  .row-lg > .offset-0,
  .row-lg > .offset-xs-0 {
    margin-left: 0%;
  }
  .column-lg > .col-0,
  .column-lg > .col-xs-0 {
    height: 0%;
    width: auto;
  }
  .row-lg > .col-1,
  .row-lg > .col-xs-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-lg > .offset-1,
  .row-lg > .offset-xs-1 {
    margin-left: 8.3333%;
  }
  .column-lg > .col-1,
  .column-lg > .col-xs-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-lg > .col-2,
  .row-lg > .col-xs-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-lg > .offset-2,
  .row-lg > .offset-xs-2 {
    margin-left: 16.6667%;
  }
  .column-lg > .col-2,
  .column-lg > .col-xs-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-lg > .col-3,
  .row-lg > .col-xs-3 {
    height: auto;
    width: 25%;
  }
  .row-lg > .offset-3,
  .row-lg > .offset-xs-3 {
    margin-left: 25%;
  }
  .column-lg > .col-3,
  .column-lg > .col-xs-3 {
    height: 25%;
    width: auto;
  }
  .row-lg > .col-4,
  .row-lg > .col-xs-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-lg > .offset-4,
  .row-lg > .offset-xs-4 {
    margin-left: 33.3333%;
  }
  .column-lg > .col-4,
  .column-lg > .col-xs-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-lg > .col-5,
  .row-lg > .col-xs-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-lg > .offset-5,
  .row-lg > .offset-xs-5 {
    margin-left: 41.6667%;
  }
  .column-lg > .col-5,
  .column-lg > .col-xs-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-lg > .col-6,
  .row-lg > .col-xs-6 {
    height: auto;
    width: 50%;
  }
  .row-lg > .offset-6,
  .row-lg > .offset-xs-6 {
    margin-left: 50%;
  }
  .column-lg > .col-6,
  .column-lg > .col-xs-6 {
    height: 50%;
    width: auto;
  }
  .row-lg > .col-7,
  .row-lg > .col-xs-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-lg > .offset-7,
  .row-lg > .offset-xs-7 {
    margin-left: 58.3333%;
  }
  .column-lg > .col-7,
  .column-lg > .col-xs-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-lg > .col-8,
  .row-lg > .col-xs-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-lg > .offset-8,
  .row-lg > .offset-xs-8 {
    margin-left: 66.6667%;
  }
  .column-lg > .col-8,
  .column-lg > .col-xs-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-lg > .col-9,
  .row-lg > .col-xs-9 {
    height: auto;
    width: 75%;
  }
  .row-lg > .offset-9,
  .row-lg > .offset-xs-9 {
    margin-left: 75%;
  }
  .column-lg > .col-9,
  .column-lg > .col-xs-9 {
    height: 75%;
    width: auto;
  }
  .row-lg > .col-10,
  .row-lg > .col-xs-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-lg > .offset-10,
  .row-lg > .offset-xs-10 {
    margin-left: 83.3333%;
  }
  .column-lg > .col-10,
  .column-lg > .col-xs-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-lg > .col-11,
  .row-lg > .col-xs-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-lg > .offset-11,
  .row-lg > .offset-xs-11 {
    margin-left: 91.6667%;
  }
  .column-lg > .col-11,
  .column-lg > .col-xs-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-lg > .col-12,
  .row-lg > .col-xs-12 {
    height: auto;
    width: 100%;
  }
  .row-lg > .offset-12,
  .row-lg > .offset-xs-12 {
    margin-left: 100%;
  }
  .column-lg > .col-12,
  .column-lg > .col-xs-12 {
    height: 100%;
    width: auto;
  }
  .row-lg > .col-sm-0 {
    height: auto;
    width: 0%;
  }
  .row-lg > .offset-sm-0 {
    margin-left: 0%;
  }
  .column-lg > .col-sm-0 {
    height: 0%;
    width: auto;
  }
  .row-lg > .col-sm-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-lg > .offset-sm-1 {
    margin-left: 8.3333%;
  }
  .column-lg > .col-sm-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-lg > .col-sm-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-lg > .offset-sm-2 {
    margin-left: 16.6667%;
  }
  .column-lg > .col-sm-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-lg > .col-sm-3 {
    height: auto;
    width: 25%;
  }
  .row-lg > .offset-sm-3 {
    margin-left: 25%;
  }
  .column-lg > .col-sm-3 {
    height: 25%;
    width: auto;
  }
  .row-lg > .col-sm-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-lg > .offset-sm-4 {
    margin-left: 33.3333%;
  }
  .column-lg > .col-sm-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-lg > .col-sm-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-lg > .offset-sm-5 {
    margin-left: 41.6667%;
  }
  .column-lg > .col-sm-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-lg > .col-sm-6 {
    height: auto;
    width: 50%;
  }
  .row-lg > .offset-sm-6 {
    margin-left: 50%;
  }
  .column-lg > .col-sm-6 {
    height: 50%;
    width: auto;
  }
  .row-lg > .col-sm-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-lg > .offset-sm-7 {
    margin-left: 58.3333%;
  }
  .column-lg > .col-sm-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-lg > .col-sm-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-lg > .offset-sm-8 {
    margin-left: 66.6667%;
  }
  .column-lg > .col-sm-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-lg > .col-sm-9 {
    height: auto;
    width: 75%;
  }
  .row-lg > .offset-sm-9 {
    margin-left: 75%;
  }
  .column-lg > .col-sm-9 {
    height: 75%;
    width: auto;
  }
  .row-lg > .col-sm-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-lg > .offset-sm-10 {
    margin-left: 83.3333%;
  }
  .column-lg > .col-sm-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-lg > .col-sm-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-lg > .offset-sm-11 {
    margin-left: 91.6667%;
  }
  .column-lg > .col-sm-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-lg > .col-sm-12 {
    height: auto;
    width: 100%;
  }
  .row-lg > .offset-sm-12 {
    margin-left: 100%;
  }
  .column-lg > .col-sm-12 {
    height: 100%;
    width: auto;
  }
  .row-lg > .col-md-0 {
    height: auto;
    width: 0%;
  }
  .row-lg > .offset-md-0 {
    margin-left: 0%;
  }
  .column-lg > .col-md-0 {
    height: 0%;
    width: auto;
  }
  .row-lg > .col-md-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-lg > .offset-md-1 {
    margin-left: 8.3333%;
  }
  .column-lg > .col-md-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-lg > .col-md-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-lg > .offset-md-2 {
    margin-left: 16.6667%;
  }
  .column-lg > .col-md-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-lg > .col-md-3 {
    height: auto;
    width: 25%;
  }
  .row-lg > .offset-md-3 {
    margin-left: 25%;
  }
  .column-lg > .col-md-3 {
    height: 25%;
    width: auto;
  }
  .row-lg > .col-md-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-lg > .offset-md-4 {
    margin-left: 33.3333%;
  }
  .column-lg > .col-md-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-lg > .col-md-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-lg > .offset-md-5 {
    margin-left: 41.6667%;
  }
  .column-lg > .col-md-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-lg > .col-md-6 {
    height: auto;
    width: 50%;
  }
  .row-lg > .offset-md-6 {
    margin-left: 50%;
  }
  .column-lg > .col-md-6 {
    height: 50%;
    width: auto;
  }
  .row-lg > .col-md-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-lg > .offset-md-7 {
    margin-left: 58.3333%;
  }
  .column-lg > .col-md-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-lg > .col-md-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-lg > .offset-md-8 {
    margin-left: 66.6667%;
  }
  .column-lg > .col-md-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-lg > .col-md-9 {
    height: auto;
    width: 75%;
  }
  .row-lg > .offset-md-9 {
    margin-left: 75%;
  }
  .column-lg > .col-md-9 {
    height: 75%;
    width: auto;
  }
  .row-lg > .col-md-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-lg > .offset-md-10 {
    margin-left: 83.3333%;
  }
  .column-lg > .col-md-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-lg > .col-md-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-lg > .offset-md-11 {
    margin-left: 91.6667%;
  }
  .column-lg > .col-md-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-lg > .col-md-12 {
    height: auto;
    width: 100%;
  }
  .row-lg > .offset-md-12 {
    margin-left: 100%;
  }
  .column-lg > .col-md-12 {
    height: 100%;
    width: auto;
  }
  .row-lg > .col-lg-0 {
    height: auto;
    width: 0%;
  }
  .row-lg > .offset-lg-0 {
    margin-left: 0%;
  }
  .column-lg > .col-lg-0 {
    height: 0%;
    width: auto;
  }
  .row-lg > .col-lg-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-lg > .offset-lg-1 {
    margin-left: 8.3333%;
  }
  .column-lg > .col-lg-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-lg > .col-lg-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-lg > .offset-lg-2 {
    margin-left: 16.6667%;
  }
  .column-lg > .col-lg-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-lg > .col-lg-3 {
    height: auto;
    width: 25%;
  }
  .row-lg > .offset-lg-3 {
    margin-left: 25%;
  }
  .column-lg > .col-lg-3 {
    height: 25%;
    width: auto;
  }
  .row-lg > .col-lg-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-lg > .offset-lg-4 {
    margin-left: 33.3333%;
  }
  .column-lg > .col-lg-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-lg > .col-lg-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-lg > .offset-lg-5 {
    margin-left: 41.6667%;
  }
  .column-lg > .col-lg-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-lg > .col-lg-6 {
    height: auto;
    width: 50%;
  }
  .row-lg > .offset-lg-6 {
    margin-left: 50%;
  }
  .column-lg > .col-lg-6 {
    height: 50%;
    width: auto;
  }
  .row-lg > .col-lg-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-lg > .offset-lg-7 {
    margin-left: 58.3333%;
  }
  .column-lg > .col-lg-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-lg > .col-lg-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-lg > .offset-lg-8 {
    margin-left: 66.6667%;
  }
  .column-lg > .col-lg-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-lg > .col-lg-9 {
    height: auto;
    width: 75%;
  }
  .row-lg > .offset-lg-9 {
    margin-left: 75%;
  }
  .column-lg > .col-lg-9 {
    height: 75%;
    width: auto;
  }
  .row-lg > .col-lg-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-lg > .offset-lg-10 {
    margin-left: 83.3333%;
  }
  .column-lg > .col-lg-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-lg > .col-lg-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-lg > .offset-lg-11 {
    margin-left: 91.6667%;
  }
  .column-lg > .col-lg-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-lg > .col-lg-12 {
    height: auto;
    width: 100%;
  }
  .row-lg > .offset-lg-12 {
    margin-left: 100%;
  }
  .column-lg > .col-lg-12 {
    height: 100%;
    width: auto;
  }
}
@media (min-width: 1920px) {
  .flex-xl-block {
    display: block;
  }
  .flex-xl-inline {
    display: inline-block;
  }
  .q-pa-xl-none {
    padding: 0 0;
  }
  .q-pl-xl-none,
  .q-px-xl-none {
    padding-left: 0;
  }
  .q-pr-xl-none,
  .q-px-xl-none {
    padding-right: 0;
  }
  .q-pt-xl-none,
  .q-py-xl-none {
    padding-top: 0;
  }
  .q-pb-xl-none,
  .q-py-xl-none {
    padding-bottom: 0;
  }
  .q-ma-xl-none {
    margin: 0 0;
  }
  .q-ml-xl-none,
  .q-mx-xl-none {
    margin-left: 0;
  }
  .q-mr-xl-none,
  .q-mx-xl-none {
    margin-right: 0;
  }
  .q-mt-xl-none,
  .q-my-xl-none {
    margin-top: 0;
  }
  .q-mb-xl-none,
  .q-my-xl-none {
    margin-bottom: 0;
  }
  .q-pa-xl-xs {
    padding: 4px 4px;
  }
  .q-pl-xl-xs,
  .q-px-xl-xs {
    padding-left: 4px;
  }
  .q-pr-xl-xs,
  .q-px-xl-xs {
    padding-right: 4px;
  }
  .q-pt-xl-xs,
  .q-py-xl-xs {
    padding-top: 4px;
  }
  .q-pb-xl-xs,
  .q-py-xl-xs {
    padding-bottom: 4px;
  }
  .q-ma-xl-xs {
    margin: 4px 4px;
  }
  .q-ml-xl-xs,
  .q-mx-xl-xs {
    margin-left: 4px;
  }
  .q-mr-xl-xs,
  .q-mx-xl-xs {
    margin-right: 4px;
  }
  .q-mt-xl-xs,
  .q-my-xl-xs {
    margin-top: 4px;
  }
  .q-mb-xl-xs,
  .q-my-xl-xs {
    margin-bottom: 4px;
  }
  .q-pa-xl-sm {
    padding: 8px 8px;
  }
  .q-pl-xl-sm,
  .q-px-xl-sm {
    padding-left: 8px;
  }
  .q-pr-xl-sm,
  .q-px-xl-sm {
    padding-right: 8px;
  }
  .q-pt-xl-sm,
  .q-py-xl-sm {
    padding-top: 8px;
  }
  .q-pb-xl-sm,
  .q-py-xl-sm {
    padding-bottom: 8px;
  }
  .q-ma-xl-sm {
    margin: 8px 8px;
  }
  .q-ml-xl-sm,
  .q-mx-xl-sm {
    margin-left: 8px;
  }
  .q-mr-xl-sm,
  .q-mx-xl-sm {
    margin-right: 8px;
  }
  .q-mt-xl-sm,
  .q-my-xl-sm {
    margin-top: 8px;
  }
  .q-mb-xl-sm,
  .q-my-xl-sm {
    margin-bottom: 8px;
  }
  .q-pa-xl-md {
    padding: 16px 16px;
  }
  .q-pl-xl-md,
  .q-px-xl-md {
    padding-left: 16px;
  }
  .q-pr-xl-md,
  .q-px-xl-md {
    padding-right: 16px;
  }
  .q-pt-xl-md,
  .q-py-xl-md {
    padding-top: 16px;
  }
  .q-pb-xl-md,
  .q-py-xl-md {
    padding-bottom: 16px;
  }
  .q-ma-xl-md {
    margin: 16px 16px;
  }
  .q-ml-xl-md,
  .q-mx-xl-md {
    margin-left: 16px;
  }
  .q-mr-xl-md,
  .q-mx-xl-md {
    margin-right: 16px;
  }
  .q-mt-xl-md,
  .q-my-xl-md {
    margin-top: 16px;
  }
  .q-mb-xl-md,
  .q-my-xl-md {
    margin-bottom: 16px;
  }
  .q-pa-xl-lg {
    padding: 24px 24px;
  }
  .q-pl-xl-lg,
  .q-px-xl-lg {
    padding-left: 24px;
  }
  .q-pr-xl-lg,
  .q-px-xl-lg {
    padding-right: 24px;
  }
  .q-pt-xl-lg,
  .q-py-xl-lg {
    padding-top: 24px;
  }
  .q-pb-xl-lg,
  .q-py-xl-lg {
    padding-bottom: 24px;
  }
  .q-ma-xl-lg {
    margin: 24px 24px;
  }
  .q-ml-xl-lg,
  .q-mx-xl-lg {
    margin-left: 24px;
  }
  .q-mr-xl-lg,
  .q-mx-xl-lg {
    margin-right: 24px;
  }
  .q-mt-xl-lg,
  .q-my-xl-lg {
    margin-top: 24px;
  }
  .q-mb-xl-lg,
  .q-my-xl-lg {
    margin-bottom: 24px;
  }
  .q-pa-xl-xl {
    padding: 48px 48px;
  }
  .q-pl-xl-xl,
  .q-px-xl-xl {
    padding-left: 48px;
  }
  .q-pr-xl-xl,
  .q-px-xl-xl {
    padding-right: 48px;
  }
  .q-pt-xl-xl,
  .q-py-xl-xl {
    padding-top: 48px;
  }
  .q-pb-xl-xl,
  .q-py-xl-xl {
    padding-bottom: 48px;
  }
  .q-ma-xl-xl {
    margin: 48px 48px;
  }
  .q-ml-xl-xl,
  .q-mx-xl-xl {
    margin-left: 48px;
  }
  .q-mr-xl-xl,
  .q-mx-xl-xl {
    margin-right: 48px;
  }
  .q-mt-xl-xl,
  .q-my-xl-xl {
    margin-top: 48px;
  }
  .q-mb-xl-xl,
  .q-my-xl-xl {
    margin-bottom: 48px;
  }
  .q-ml-xl-auto,
  .q-mx-xl-auto {
    margin-left: auto;
  }
  .q-mr-xl-auto,
  .q-mx-xl-auto {
    margin-right: auto;
  }
  .row.inline-xl,
  .column.inline-xl,
  .flex.inline-xl {
    display: inline-flex;
  }
  .row-xl,
  .column-xl,
  .flex-xl {
    display: flex;
    flex-wrap: wrap;
  }
  .row-xl.inline,
  .column-xl.inline,
  .flex-xl.inline,
  .row-xl.inline-xl,
  .column-xl.inline-xl,
  .flex-xl.inline-xl {
    display: inline-flex;
  }
  .row.reverse-xl {
    flex-direction: row-reverse;
  }
  .row-xl {
    flex-direction: row;
  }
  .row-xl.reverse,
  .row-xl.reverse-xl {
    flex-direction: row-reverse;
  }
  .column.reverse-xl {
    flex-direction: column-reverse;
  }
  .column-xl {
    flex-direction: column;
  }
  .column-xl.reverse,
  .column-xl.reverse-xl {
    flex-direction: column-reverse;
  }
  .wrap-xl {
    flex-wrap: wrap;
  }
  .no-wrap-xl {
    flex-wrap: nowrap;
  }
  .reverse-wrap-xl {
    flex-wrap: wrap-reverse;
  }
  .order-xl-first {
    order: -10000;
  }
  .order-xl-last {
    order: 10000;
  }
  .order-xl-none {
    order: 0;
  }
  .justify-xl-start {
    justify-content: flex-start;
  }
  .justify-xl-end {
    justify-content: flex-end;
  }
  .justify-xl-center,
  .flex-xl-center {
    justify-content: center;
  }
  .justify-xl-between {
    justify-content: space-between;
  }
  .justify-xl-around {
    justify-content: space-around;
  }
  .justify-xl-evenly {
    justify-content: space-evenly;
  }
  .items-xl-start {
    align-items: flex-start;
  }
  .items-xl-end {
    align-items: flex-end;
  }
  .items-xl-center,
  .flex-xl-center {
    align-items: center;
  }
  .items-xl-baseline {
    align-items: baseline;
  }
  .items-xl-stretch {
    align-items: stretch;
  }
  .content-xl-start {
    align-content: flex-start;
  }
  .content-xl-end {
    align-content: flex-end;
  }
  .content-xl-center {
    align-content: center;
  }
  .content-xl-between {
    align-content: space-between;
  }
  .content-xl-around {
    align-content: space-around;
  }
  .self-xl-start {
    align-self: flex-start;
  }
  .self-xl-end {
    align-self: flex-end;
  }
  .self-xl-center {
    align-self: center;
  }
  .self-xl-baseline {
    align-self: baseline;
  }
  .self-xl-stretch {
    align-self: stretch;
  }
  .q-gutter-xl-x-none,
  .q-gutter-xl-none {
    margin-left: 0;
  }
  .q-gutter-xl-x-none > *,
  .q-gutter-xl-none > * {
    margin-left: 0;
  }
  .q-gutter-xl-y-none,
  .q-gutter-xl-none {
    margin-top: 0;
  }
  .q-gutter-xl-y-none > *,
  .q-gutter-xl-none > * {
    margin-top: 0;
  }
  .q-col-gutter-xl-x-none,
  .q-col-gutter-xl-none {
    margin-left: 0;
  }
  .q-col-gutter-xl-x-none > *,
  .q-col-gutter-xl-none > * {
    padding-left: 0;
  }
  .q-col-gutter-xl-y-none,
  .q-col-gutter-xl-none {
    margin-top: 0;
  }
  .q-col-gutter-xl-y-none > *,
  .q-col-gutter-xl-none > * {
    padding-top: 0;
  }
  .q-gutter-xl-x-xs,
  .q-gutter-xl-xs {
    margin-left: -4px;
  }
  .q-gutter-xl-x-xs > *,
  .q-gutter-xl-xs > * {
    margin-left: 4px;
  }
  .q-gutter-xl-y-xs,
  .q-gutter-xl-xs {
    margin-top: -4px;
  }
  .q-gutter-xl-y-xs > *,
  .q-gutter-xl-xs > * {
    margin-top: 4px;
  }
  .q-col-gutter-xl-x-xs,
  .q-col-gutter-xl-xs {
    margin-left: -4px;
  }
  .q-col-gutter-xl-x-xs > *,
  .q-col-gutter-xl-xs > * {
    padding-left: 4px;
  }
  .q-col-gutter-xl-y-xs,
  .q-col-gutter-xl-xs {
    margin-top: -4px;
  }
  .q-col-gutter-xl-y-xs > *,
  .q-col-gutter-xl-xs > * {
    padding-top: 4px;
  }
  .q-gutter-xl-x-sm,
  .q-gutter-xl-sm {
    margin-left: -8px;
  }
  .q-gutter-xl-x-sm > *,
  .q-gutter-xl-sm > * {
    margin-left: 8px;
  }
  .q-gutter-xl-y-sm,
  .q-gutter-xl-sm {
    margin-top: -8px;
  }
  .q-gutter-xl-y-sm > *,
  .q-gutter-xl-sm > * {
    margin-top: 8px;
  }
  .q-col-gutter-xl-x-sm,
  .q-col-gutter-xl-sm {
    margin-left: -8px;
  }
  .q-col-gutter-xl-x-sm > *,
  .q-col-gutter-xl-sm > * {
    padding-left: 8px;
  }
  .q-col-gutter-xl-y-sm,
  .q-col-gutter-xl-sm {
    margin-top: -8px;
  }
  .q-col-gutter-xl-y-sm > *,
  .q-col-gutter-xl-sm > * {
    padding-top: 8px;
  }
  .q-gutter-xl-x-md,
  .q-gutter-xl-md {
    margin-left: -16px;
  }
  .q-gutter-xl-x-md > *,
  .q-gutter-xl-md > * {
    margin-left: 16px;
  }
  .q-gutter-xl-y-md,
  .q-gutter-xl-md {
    margin-top: -16px;
  }
  .q-gutter-xl-y-md > *,
  .q-gutter-xl-md > * {
    margin-top: 16px;
  }
  .q-col-gutter-xl-x-md,
  .q-col-gutter-xl-md {
    margin-left: -16px;
  }
  .q-col-gutter-xl-x-md > *,
  .q-col-gutter-xl-md > * {
    padding-left: 16px;
  }
  .q-col-gutter-xl-y-md,
  .q-col-gutter-xl-md {
    margin-top: -16px;
  }
  .q-col-gutter-xl-y-md > *,
  .q-col-gutter-xl-md > * {
    padding-top: 16px;
  }
  .q-gutter-xl-x-lg,
  .q-gutter-xl-lg {
    margin-left: -24px;
  }
  .q-gutter-xl-x-lg > *,
  .q-gutter-xl-lg > * {
    margin-left: 24px;
  }
  .q-gutter-xl-y-lg,
  .q-gutter-xl-lg {
    margin-top: -24px;
  }
  .q-gutter-xl-y-lg > *,
  .q-gutter-xl-lg > * {
    margin-top: 24px;
  }
  .q-col-gutter-xl-x-lg,
  .q-col-gutter-xl-lg {
    margin-left: -24px;
  }
  .q-col-gutter-xl-x-lg > *,
  .q-col-gutter-xl-lg > * {
    padding-left: 24px;
  }
  .q-col-gutter-xl-y-lg,
  .q-col-gutter-xl-lg {
    margin-top: -24px;
  }
  .q-col-gutter-xl-y-lg > *,
  .q-col-gutter-xl-lg > * {
    padding-top: 24px;
  }
  .q-gutter-xl-x-xl,
  .q-gutter-xl-xl {
    margin-left: -48px;
  }
  .q-gutter-xl-x-xl > *,
  .q-gutter-xl-xl > * {
    margin-left: 48px;
  }
  .q-gutter-xl-y-xl,
  .q-gutter-xl-xl {
    margin-top: -48px;
  }
  .q-gutter-xl-y-xl > *,
  .q-gutter-xl-xl > * {
    margin-top: 48px;
  }
  .q-col-gutter-xl-x-xl,
  .q-col-gutter-xl-xl {
    margin-left: -48px;
  }
  .q-col-gutter-xl-x-xl > *,
  .q-col-gutter-xl-xl > * {
    padding-left: 48px;
  }
  .q-col-gutter-xl-y-xl,
  .q-col-gutter-xl-xl {
    margin-top: -48px;
  }
  .q-col-gutter-xl-y-xl > *,
  .q-col-gutter-xl-xl > * {
    padding-top: 48px;
  }
  .row-xl > .col-0,
  .row-xl > .col-xs-0 {
    height: auto;
    width: 0%;
  }
  .row-xl > .offset-0,
  .row-xl > .offset-xs-0 {
    margin-left: 0%;
  }
  .column-xl > .col-0,
  .column-xl > .col-xs-0 {
    height: 0%;
    width: auto;
  }
  .row-xl > .col-1,
  .row-xl > .col-xs-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-xl > .offset-1,
  .row-xl > .offset-xs-1 {
    margin-left: 8.3333%;
  }
  .column-xl > .col-1,
  .column-xl > .col-xs-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-xl > .col-2,
  .row-xl > .col-xs-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-xl > .offset-2,
  .row-xl > .offset-xs-2 {
    margin-left: 16.6667%;
  }
  .column-xl > .col-2,
  .column-xl > .col-xs-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-xl > .col-3,
  .row-xl > .col-xs-3 {
    height: auto;
    width: 25%;
  }
  .row-xl > .offset-3,
  .row-xl > .offset-xs-3 {
    margin-left: 25%;
  }
  .column-xl > .col-3,
  .column-xl > .col-xs-3 {
    height: 25%;
    width: auto;
  }
  .row-xl > .col-4,
  .row-xl > .col-xs-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-xl > .offset-4,
  .row-xl > .offset-xs-4 {
    margin-left: 33.3333%;
  }
  .column-xl > .col-4,
  .column-xl > .col-xs-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-xl > .col-5,
  .row-xl > .col-xs-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-xl > .offset-5,
  .row-xl > .offset-xs-5 {
    margin-left: 41.6667%;
  }
  .column-xl > .col-5,
  .column-xl > .col-xs-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-xl > .col-6,
  .row-xl > .col-xs-6 {
    height: auto;
    width: 50%;
  }
  .row-xl > .offset-6,
  .row-xl > .offset-xs-6 {
    margin-left: 50%;
  }
  .column-xl > .col-6,
  .column-xl > .col-xs-6 {
    height: 50%;
    width: auto;
  }
  .row-xl > .col-7,
  .row-xl > .col-xs-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-xl > .offset-7,
  .row-xl > .offset-xs-7 {
    margin-left: 58.3333%;
  }
  .column-xl > .col-7,
  .column-xl > .col-xs-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-xl > .col-8,
  .row-xl > .col-xs-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-xl > .offset-8,
  .row-xl > .offset-xs-8 {
    margin-left: 66.6667%;
  }
  .column-xl > .col-8,
  .column-xl > .col-xs-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-xl > .col-9,
  .row-xl > .col-xs-9 {
    height: auto;
    width: 75%;
  }
  .row-xl > .offset-9,
  .row-xl > .offset-xs-9 {
    margin-left: 75%;
  }
  .column-xl > .col-9,
  .column-xl > .col-xs-9 {
    height: 75%;
    width: auto;
  }
  .row-xl > .col-10,
  .row-xl > .col-xs-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-xl > .offset-10,
  .row-xl > .offset-xs-10 {
    margin-left: 83.3333%;
  }
  .column-xl > .col-10,
  .column-xl > .col-xs-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-xl > .col-11,
  .row-xl > .col-xs-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-xl > .offset-11,
  .row-xl > .offset-xs-11 {
    margin-left: 91.6667%;
  }
  .column-xl > .col-11,
  .column-xl > .col-xs-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-xl > .col-12,
  .row-xl > .col-xs-12 {
    height: auto;
    width: 100%;
  }
  .row-xl > .offset-12,
  .row-xl > .offset-xs-12 {
    margin-left: 100%;
  }
  .column-xl > .col-12,
  .column-xl > .col-xs-12 {
    height: 100%;
    width: auto;
  }
  .row-xl > .col-sm-0 {
    height: auto;
    width: 0%;
  }
  .row-xl > .offset-sm-0 {
    margin-left: 0%;
  }
  .column-xl > .col-sm-0 {
    height: 0%;
    width: auto;
  }
  .row-xl > .col-sm-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-xl > .offset-sm-1 {
    margin-left: 8.3333%;
  }
  .column-xl > .col-sm-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-xl > .col-sm-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-xl > .offset-sm-2 {
    margin-left: 16.6667%;
  }
  .column-xl > .col-sm-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-xl > .col-sm-3 {
    height: auto;
    width: 25%;
  }
  .row-xl > .offset-sm-3 {
    margin-left: 25%;
  }
  .column-xl > .col-sm-3 {
    height: 25%;
    width: auto;
  }
  .row-xl > .col-sm-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-xl > .offset-sm-4 {
    margin-left: 33.3333%;
  }
  .column-xl > .col-sm-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-xl > .col-sm-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-xl > .offset-sm-5 {
    margin-left: 41.6667%;
  }
  .column-xl > .col-sm-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-xl > .col-sm-6 {
    height: auto;
    width: 50%;
  }
  .row-xl > .offset-sm-6 {
    margin-left: 50%;
  }
  .column-xl > .col-sm-6 {
    height: 50%;
    width: auto;
  }
  .row-xl > .col-sm-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-xl > .offset-sm-7 {
    margin-left: 58.3333%;
  }
  .column-xl > .col-sm-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-xl > .col-sm-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-xl > .offset-sm-8 {
    margin-left: 66.6667%;
  }
  .column-xl > .col-sm-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-xl > .col-sm-9 {
    height: auto;
    width: 75%;
  }
  .row-xl > .offset-sm-9 {
    margin-left: 75%;
  }
  .column-xl > .col-sm-9 {
    height: 75%;
    width: auto;
  }
  .row-xl > .col-sm-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-xl > .offset-sm-10 {
    margin-left: 83.3333%;
  }
  .column-xl > .col-sm-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-xl > .col-sm-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-xl > .offset-sm-11 {
    margin-left: 91.6667%;
  }
  .column-xl > .col-sm-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-xl > .col-sm-12 {
    height: auto;
    width: 100%;
  }
  .row-xl > .offset-sm-12 {
    margin-left: 100%;
  }
  .column-xl > .col-sm-12 {
    height: 100%;
    width: auto;
  }
  .row-xl > .col-md-0 {
    height: auto;
    width: 0%;
  }
  .row-xl > .offset-md-0 {
    margin-left: 0%;
  }
  .column-xl > .col-md-0 {
    height: 0%;
    width: auto;
  }
  .row-xl > .col-md-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-xl > .offset-md-1 {
    margin-left: 8.3333%;
  }
  .column-xl > .col-md-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-xl > .col-md-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-xl > .offset-md-2 {
    margin-left: 16.6667%;
  }
  .column-xl > .col-md-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-xl > .col-md-3 {
    height: auto;
    width: 25%;
  }
  .row-xl > .offset-md-3 {
    margin-left: 25%;
  }
  .column-xl > .col-md-3 {
    height: 25%;
    width: auto;
  }
  .row-xl > .col-md-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-xl > .offset-md-4 {
    margin-left: 33.3333%;
  }
  .column-xl > .col-md-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-xl > .col-md-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-xl > .offset-md-5 {
    margin-left: 41.6667%;
  }
  .column-xl > .col-md-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-xl > .col-md-6 {
    height: auto;
    width: 50%;
  }
  .row-xl > .offset-md-6 {
    margin-left: 50%;
  }
  .column-xl > .col-md-6 {
    height: 50%;
    width: auto;
  }
  .row-xl > .col-md-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-xl > .offset-md-7 {
    margin-left: 58.3333%;
  }
  .column-xl > .col-md-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-xl > .col-md-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-xl > .offset-md-8 {
    margin-left: 66.6667%;
  }
  .column-xl > .col-md-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-xl > .col-md-9 {
    height: auto;
    width: 75%;
  }
  .row-xl > .offset-md-9 {
    margin-left: 75%;
  }
  .column-xl > .col-md-9 {
    height: 75%;
    width: auto;
  }
  .row-xl > .col-md-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-xl > .offset-md-10 {
    margin-left: 83.3333%;
  }
  .column-xl > .col-md-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-xl > .col-md-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-xl > .offset-md-11 {
    margin-left: 91.6667%;
  }
  .column-xl > .col-md-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-xl > .col-md-12 {
    height: auto;
    width: 100%;
  }
  .row-xl > .offset-md-12 {
    margin-left: 100%;
  }
  .column-xl > .col-md-12 {
    height: 100%;
    width: auto;
  }
  .row-xl > .col-lg-0 {
    height: auto;
    width: 0%;
  }
  .row-xl > .offset-lg-0 {
    margin-left: 0%;
  }
  .column-xl > .col-lg-0 {
    height: 0%;
    width: auto;
  }
  .row-xl > .col-lg-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-xl > .offset-lg-1 {
    margin-left: 8.3333%;
  }
  .column-xl > .col-lg-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-xl > .col-lg-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-xl > .offset-lg-2 {
    margin-left: 16.6667%;
  }
  .column-xl > .col-lg-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-xl > .col-lg-3 {
    height: auto;
    width: 25%;
  }
  .row-xl > .offset-lg-3 {
    margin-left: 25%;
  }
  .column-xl > .col-lg-3 {
    height: 25%;
    width: auto;
  }
  .row-xl > .col-lg-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-xl > .offset-lg-4 {
    margin-left: 33.3333%;
  }
  .column-xl > .col-lg-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-xl > .col-lg-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-xl > .offset-lg-5 {
    margin-left: 41.6667%;
  }
  .column-xl > .col-lg-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-xl > .col-lg-6 {
    height: auto;
    width: 50%;
  }
  .row-xl > .offset-lg-6 {
    margin-left: 50%;
  }
  .column-xl > .col-lg-6 {
    height: 50%;
    width: auto;
  }
  .row-xl > .col-lg-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-xl > .offset-lg-7 {
    margin-left: 58.3333%;
  }
  .column-xl > .col-lg-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-xl > .col-lg-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-xl > .offset-lg-8 {
    margin-left: 66.6667%;
  }
  .column-xl > .col-lg-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-xl > .col-lg-9 {
    height: auto;
    width: 75%;
  }
  .row-xl > .offset-lg-9 {
    margin-left: 75%;
  }
  .column-xl > .col-lg-9 {
    height: 75%;
    width: auto;
  }
  .row-xl > .col-lg-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-xl > .offset-lg-10 {
    margin-left: 83.3333%;
  }
  .column-xl > .col-lg-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-xl > .col-lg-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-xl > .offset-lg-11 {
    margin-left: 91.6667%;
  }
  .column-xl > .col-lg-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-xl > .col-lg-12 {
    height: auto;
    width: 100%;
  }
  .row-xl > .offset-lg-12 {
    margin-left: 100%;
  }
  .column-xl > .col-lg-12 {
    height: 100%;
    width: auto;
  }
  .row-xl > .col-xl-0 {
    height: auto;
    width: 0%;
  }
  .row-xl > .offset-xl-0 {
    margin-left: 0%;
  }
  .column-xl > .col-xl-0 {
    height: 0%;
    width: auto;
  }
  .row-xl > .col-xl-1 {
    height: auto;
    width: 8.3333%;
  }
  .row-xl > .offset-xl-1 {
    margin-left: 8.3333%;
  }
  .column-xl > .col-xl-1 {
    height: 8.3333%;
    width: auto;
  }
  .row-xl > .col-xl-2 {
    height: auto;
    width: 16.6667%;
  }
  .row-xl > .offset-xl-2 {
    margin-left: 16.6667%;
  }
  .column-xl > .col-xl-2 {
    height: 16.6667%;
    width: auto;
  }
  .row-xl > .col-xl-3 {
    height: auto;
    width: 25%;
  }
  .row-xl > .offset-xl-3 {
    margin-left: 25%;
  }
  .column-xl > .col-xl-3 {
    height: 25%;
    width: auto;
  }
  .row-xl > .col-xl-4 {
    height: auto;
    width: 33.3333%;
  }
  .row-xl > .offset-xl-4 {
    margin-left: 33.3333%;
  }
  .column-xl > .col-xl-4 {
    height: 33.3333%;
    width: auto;
  }
  .row-xl > .col-xl-5 {
    height: auto;
    width: 41.6667%;
  }
  .row-xl > .offset-xl-5 {
    margin-left: 41.6667%;
  }
  .column-xl > .col-xl-5 {
    height: 41.6667%;
    width: auto;
  }
  .row-xl > .col-xl-6 {
    height: auto;
    width: 50%;
  }
  .row-xl > .offset-xl-6 {
    margin-left: 50%;
  }
  .column-xl > .col-xl-6 {
    height: 50%;
    width: auto;
  }
  .row-xl > .col-xl-7 {
    height: auto;
    width: 58.3333%;
  }
  .row-xl > .offset-xl-7 {
    margin-left: 58.3333%;
  }
  .column-xl > .col-xl-7 {
    height: 58.3333%;
    width: auto;
  }
  .row-xl > .col-xl-8 {
    height: auto;
    width: 66.6667%;
  }
  .row-xl > .offset-xl-8 {
    margin-left: 66.6667%;
  }
  .column-xl > .col-xl-8 {
    height: 66.6667%;
    width: auto;
  }
  .row-xl > .col-xl-9 {
    height: auto;
    width: 75%;
  }
  .row-xl > .offset-xl-9 {
    margin-left: 75%;
  }
  .column-xl > .col-xl-9 {
    height: 75%;
    width: auto;
  }
  .row-xl > .col-xl-10 {
    height: auto;
    width: 83.3333%;
  }
  .row-xl > .offset-xl-10 {
    margin-left: 83.3333%;
  }
  .column-xl > .col-xl-10 {
    height: 83.3333%;
    width: auto;
  }
  .row-xl > .col-xl-11 {
    height: auto;
    width: 91.6667%;
  }
  .row-xl > .offset-xl-11 {
    margin-left: 91.6667%;
  }
  .column-xl > .col-xl-11 {
    height: 91.6667%;
    width: auto;
  }
  .row-xl > .col-xl-12 {
    height: auto;
    width: 100%;
  }
  .row-xl > .offset-xl-12 {
    margin-left: 100%;
  }
  .column-xl > .col-xl-12 {
    height: 100%;
    width: auto;
  }
}
/*# sourceMappingURL=node_modules/quasar/src/css/flex-addon.css.map */